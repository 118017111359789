import classes from '../../Cabinet.module.css';

function CabinetSubscriptionInactive() {
    return (
        <p className={classes['cabinet-tabs__text']}>
            У вас сейчас нет активных подписок.
            <br />
            Стоимость подписки составляет 1000 рублей в месяц. Подписка устанавливается на
            календарный месяц. При оформлении подписки вы соглашаетесь на автоматическое списание
            средств. Подписку можно будет отменить в любой момент, и доступ к приложению останется
            до конца оплаченного периода.
        </p>
    );
}

export default CabinetSubscriptionInactive;
