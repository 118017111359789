import IconButton from '@mui/material/IconButton';

import googleAuthButton from '../../../../assets/images/google_auth_button.svg';
import { buildApiUrl } from '../../../../services/api';

import classes from './GoogleAuthButton.module.css';

function GoogleAuthButton() {
    return (
        <IconButton
            href={`${buildApiUrl('/connection/oauth/login')}?redirectUrl=${encodeURIComponent(
                document.location.href
            )}&appId=google`}
            aria-label="Sign in with Google"
            className={classes['google-button']}
        >
            <img src={googleAuthButton} width="200" height="46" alt="" aria-hidden="true" />
        </IconButton>
    );
}

export default GoogleAuthButton;
