import AppLayout from '../../layouts/AppLayout/AppLayout';
import MainHeader from '../../Components/MainHeader/MainHeader';
import Cabinet from '../../Components/Cabinet/Cabinet';
import MainFooter from '../../Components/MainFooter/MainFooter';

function CabinetPage() {
    return (
        <AppLayout>
            <MainHeader variant="cabinet" />
            <main>
                <Cabinet />
            </main>
            <MainFooter />
        </AppLayout>
    );
}

export default CabinetPage;
