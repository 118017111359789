import classes from '../../Cabinet.module.css';

type CabinetSubscriptionPlanTrialProps = {
    subscriptionEndDate: string | undefined;
};

function CabinetSubscriptionTrial({ subscriptionEndDate }: CabinetSubscriptionPlanTrialProps) {
    return (
        <div>
            <p className={classes['cabinet-tabs__text']}>
                Тарифный план: <b>Пробная подписка</b>
            </p>
            <p className={classes['cabinet-tabs__text']}>
                Подписка активна до: <b>{subscriptionEndDate}</b>
            </p>

            <p className={classes['cabinet-tabs__text']}>
                Пока действует пробный доступ вы можете приобрести тарифный план с платежом в 1
                рубль. Списания по тарифу начнутся, когда кончится период пробного доступа. Платёж в
                1 рубль нужен, чтобы запомнить вашу банковскую карту. Дальнейшие списания средств с
                карты будут автоматическими.
                <br />
                Подписку можно будет отменить в любой момент, и доступ к приложению останется до
                конца оплаченного периода.
            </p>
        </div>
    );
}

export default CabinetSubscriptionTrial;
