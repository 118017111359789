import { useAlertContext } from '../../Context/AlertContext';
import Typography from '@mui/material/Typography';
import CustomContainer from '../CustomContainer/CustomContainer';
import Box from '@mui/material/Box';
import LinkIcon from '@mui/icons-material/Link';
import classes from './Support.module.css';
import IconButton from '@mui/material/IconButton';

function Support() {
    const showAlert = useAlertContext();

    const copyLinkToClipboard = (id: string) => {
        const url = `${window.location.origin}${window.location.pathname}#${id}`;
        navigator.clipboard.writeText(url).then(
            () => {
                showAlert('Ссылка скопирована', 'success');
            },
            (err) => {
                showAlert('Произошла ошибка', 'error');
            }
        );
    };

    return (
        <section className={classes['support']} aria-labelledby="support_title">
            <CustomContainer>
                <Typography
                    variant="h1"
                    component="h1"
                    color="primary"
                    className={classes['support__title']}
                    id="support_title"
                >
                    Помощь
                </Typography>
                <Box>
                    <Typography>
                        На этой странице приведены распространённые вопросы, проблемы и их решения.
                        <br />
                        Если вы не нашли своей проблемы или описанные решения вам не помогли,
                        пожалуйста, напишите нам в службу поддержки по адресу электронной почты{' '}
                        <a href="mailto:info@wattattack.com">info@wattattack.com</a>, и мы
                        постараемся вам помочь!
                    </Typography>

                    <Typography variant="h2">Подключение устройств</Typography>
                    <Typography>
                        Подключение беспроводных устройств может сопровождаться неочевидными
                        проблемами. Прямо скажем, что беспроводные интерфейсы капризные.
                        <br />
                        Ниже вы сможете найти решение наиболее распространённых проблем, связанных с
                        подключением измерителей мощности, станков, пульсометров и прочего
                        оборудования к платформе WattAttack.
                    </Typography>
                    <Typography variant="subtitle2">Быстрые советы:</Typography>
                    <ul className={classes['support__list']}>
                        <li>Выключите/включите Bluetooth;</li>
                        <li>Выключите/включите устройство;</li>
                        <li>
                            Убедитесь, что устройство не подключено к спортивным часам или
                            велокомпьютеру;
                        </li>
                    </ul>

                    <Typography
                        variant="h3"
                        id="no_device"
                        className={classes['support__question']}
                    >
                        Я не вижу своего устройства в списке устройств{' '}
                        <IconButton
                            onClick={() => copyLinkToClipboard('no_device')}
                            title="Копировать ссылку"
                            aria-label="Копировать ссылку"
                        >
                            <LinkIcon aria-hidden="true" fontSize="large" />
                        </IconButton>
                    </Typography>
                    <ul className={classes['support__list']}>
                        <li>
                            Попробуйте просто немного подождать. Поиск беспроводных устройств
                            занимает некоторое время, которое зависит от многих факторов. Некоторые
                            устройства могут появиться в списке через 2 минуты. Если этого не
                            произошло, нажмите на кнопку «<b>Очистить устройства</b>» и подождите
                            ещё;
                        </li>
                        <li>
                            Если устройство так и не появилось в списке, проверьте, что ваш
                            беспроводной интерфейс (Блютус или Ant+) включен в системе:
                            <br />
                            После этого убедитесь, что включено само устройство, батарейка в нём не
                            разрядилась. Если вы используете USB Bluetooth приёмник, то попробуйте
                            его извлечь и вставить снова, или же включить и выключить Bluetooth в
                            системе (но не делайте этого, если ваша мышка и клавиатура подключены по
                            Bluetooth, потому что вы не сможете его включить опять). Физически
                            выключить и включить устройство (достать батарейку, выключить станок из
                            сети);
                        </li>
                        <li>Попробуйте перезагрузить компьютер;</li>
                        <li>
                            Распространённой причиной отсутствия беспроводного устройства в списке
                            может быть его предыдущее сопряжение с другим фитнес-аксессуаром.
                            Например, велосипедным компьютером или спортивными-часами. Один источник
                            мощности, каденса или пульса по одному протоколу может быть подключен
                            только к одному другому устройству. Поэтому необходимо убедиться, что
                            ваше устройство сейчас не подключено к чему-то ещё;
                        </li>
                        <li>
                            Если вы запустили WattAttack на мобильном устройстве, то убедитесь, что
                            в WattAttack есть разрешение на использование Bluetooth и геолокации.
                            Последнее необходимо для правильной работы OS Android (мы не собираем
                            данные о вашем местоположении);
                        </li>
                        <li>
                            На OS Android частое нажатие кнопки «Очистить устройства» может
                            перевести Bluetooth интерфейс в энергосберегающий режим. Чтобы из него
                            выйти, придётся перезапустить WattAttack.
                        </li>
                    </ul>

                    <Typography variant="h3" id="no_data" className={classes['support__question']}>
                        Я подключил своё устройство, но оно не передаёт данные
                        <IconButton
                            onClick={() => copyLinkToClipboard('no_data')}
                            title="Копировать ссылку"
                            aria-label="Копировать ссылку"
                        >
                            <LinkIcon aria-hidden="true" fontSize="large" />
                        </IconButton>
                    </Typography>
                    <ul className={classes['support__list']}>
                        <li>
                            Если вы зашли на карту, а мощность или пульс не передаются, попробуйте
                            немного подождать (в случае со станком или измерителем мощности нужно
                            вращать педали). Система может устанавливать соединение какое-то время;
                        </li>
                        <li>
                            Откройте меню, выберите пункт «Подключить устройства», нажмите в
                            появившемся окне «Очистить устройства», подождите, пока ваше устройство
                            не будет найдено и подключите его снова;
                        </li>
                        <li>Проверьте состояние батарейки своего устройства;</li>
                        <li>
                            Проверьте, что ваше устройство не подключено ранее к другому компьютеру
                            или фитнес-аксессуару, такому как велокомпьютер или спортивные часы
                            (устройство всё равно может быть в списке доступных для подключения);
                        </li>
                        <li>
                            В помещении могут создаваться помехи для работы беспроводных устройств
                            другим оборудованием, даже вентилятором. Попробуйте выключить или
                            перенести оборудование, которое размещено между устройством и
                            приёмником;
                        </li>
                        <li>
                            В редких случаях на сигнал могут влиять так называемые глушилки.
                            Попробуйте подключить устройство в другом помещении.{' '}
                        </li>
                    </ul>

                    <Typography
                        variant="h3"
                        id="zero_power"
                        className={classes['support__question']}
                    >
                        Я подключил свой станок, но на экране мощность 0
                        <IconButton
                            onClick={() => copyLinkToClipboard('zero_power')}
                            title="Копировать ссылку"
                            aria-label="Копировать ссылку"
                        >
                            <LinkIcon aria-hidden="true" fontSize="large" />
                        </IconButton>
                    </Typography>
                    <ul className={classes['support__list']}>
                        <li>
                            Убедитесь, что вы подключили велостанок в разделе «Источники мощности»,
                            а не «Управляющий тренажёр»;
                        </li>
                        <li>Попробуйте выключить и включить тренажёр;</li>
                        <li>
                            Убедитесь, что тренажёр уже не подключен к спортивным часам или
                            велокомпьютеру.
                        </li>
                    </ul>

                    <Typography variant="h3" id="no_smart" className={classes['support__question']}>
                        Не работает имитация градиента или Erg режим тренировок
                        <IconButton
                            onClick={() => copyLinkToClipboard('no_smart')}
                            title="Копировать ссылку"
                            aria-label="Копировать ссылку"
                        >
                            <LinkIcon aria-hidden="true" fontSize="large" />
                        </IconButton>
                    </Typography>
                    <ul className={classes['support__list']}>
                        <li>
                            Убедитесь, что ползунок имитации градиента в главном меню или меню
                            «Подключение устройств» установлен на значение отличное от нуля;
                        </li>
                        <li>
                            Убедитесь, что во режиме тренировки включен Erg режим (нужно нажать на
                            красную кнопку Erg, чтобы она стала зелёной);
                        </li>
                        <li>
                            Некоторые велосипедные станки (например, Magene) реализуют несколько
                            протоколов подключения. И только один из этих протоколов позволяет
                            имитацию градиента или Erg режим тренировки. Чаще всего эти протоколы
                            называются Smart T или Smart Magene/Thinkrider/Tacx. Выберите при
                            подключении устройства из списка станок, у которого в описании есть эти
                            слова.
                        </li>
                    </ul>

                    <Typography variant="h2">Стабильность работы приложения</Typography>
                    <Typography
                        variant="h3"
                        id="character_lags"
                        className={classes['support__question']}
                    >
                        Персонаж на экране дёргается
                        <IconButton
                            onClick={() => copyLinkToClipboard('character_lags')}
                            title="Копировать ссылку"
                            aria-label="Копировать ссылку"
                        >
                            <LinkIcon aria-hidden="true" fontSize="large" />
                        </IconButton>
                    </Typography>
                    <ul className={classes['support__list']}>
                        <li>
                            Попробуйте понизить качество графики. В меню выберите «Настройки», в
                            пункте «Качество графики» выберите пункт с качеством ниже, чем
                            установлен сейчас.
                        </li>
                    </ul>

                    <Typography
                        variant="h3"
                        id="windows_crashes"
                        className={classes['support__question']}
                    >
                        Приложение на платформе Windows «вылетает»
                        <IconButton
                            onClick={() => copyLinkToClipboard('windows_crashes')}
                            title="Копировать ссылку"
                            aria-label="Копировать ссылку"
                        >
                            <LinkIcon aria-hidden="true" fontSize="large" />
                        </IconButton>
                    </Typography>
                    <ul className={classes['support__list']}>
                        <li>
                            Если у вас встроенная графическая карта от Intel, то попробуйте обновить
                            драйверы. Если драйверы не удаётся скачать с официального сайта (Intel
                            блокирует Россию), то можете их скачать по ссылке:{' '}
                            <a href="https://disk.yandex.ru/d/xbzxUj57QIWJXw">
                                https://disk.yandex.ru/­d/xbzxUj57QIWJXw
                            </a>
                            .
                        </li>
                    </ul>

                    <Typography
                        variant="h3"
                        id="mouse_disappears"
                        className={classes['support__question']}
                    >
                        Пропадает курсор мышки
                        <IconButton
                            onClick={() => copyLinkToClipboard('mouse_disappears')}
                            title="Копировать ссылку"
                            aria-label="Копировать ссылку"
                        >
                            <LinkIcon aria-hidden="true" fontSize="large" />
                        </IconButton>
                    </Typography>
                    <ul className={classes['support__list']}>
                        <li>Нажмите правой кнопкой мыши несколько раз.</li>
                    </ul>

                    <Typography
                        variant="h3"
                        id="character_freezes"
                        className={classes['support__question']}
                    >
                        Персонаж зависает на месте, а потом резко ускоряется
                        <IconButton
                            onClick={() => copyLinkToClipboard('character_freezes')}
                            title="Копировать ссылку"
                            aria-label="Копировать ссылку"
                        >
                            <LinkIcon aria-hidden="true" fontSize="large" />
                        </IconButton>
                    </Typography>
                    <ul className={classes['support__list']}>
                        <li>
                            Такое случается при обрыве интернет-соединения и задержках в сети.
                            Постарайтесь обеспечить стабильное интернет-соединение.
                        </li>
                    </ul>

                    <Typography variant="h2">Регистрация</Typography>
                    <Typography
                        variant="h3"
                        id="email_not_confirmed"
                        className={classes['support__question']}
                    >
                        Не приходит письмо со ссылкой для подтверждения адреса электронной почты
                        <IconButton
                            onClick={() => copyLinkToClipboard('email_not_confirmed')}
                            title="Копировать ссылку"
                            aria-label="Копировать ссылку"
                        >
                            <LinkIcon aria-hidden="true" fontSize="large" />
                        </IconButton>
                    </Typography>
                    <ul className={classes['support__list']}>
                        <li>Проверьте папки «Спам» и «Нежелательная почта»;</li>
                        <li>
                            В личном кабинете вы можете отправить письмо со ссылкой для
                            подтверждения ещё раз;
                        </li>
                        <li>
                            Если это не помогает, то напишите нам письмо на почту{' '}
                            <a href="mailto:info@wattattack.com">info@wattattack.com</a>, с
                            указанием адреса электронной почты, на который вы зарегистрировались.
                        </li>
                    </ul>

                    <Typography
                        variant="h3"
                        id="password_not_set"
                        className={classes['support__question']}
                    >
                        В личном кабинете есть предупреждение, что необходимо сменить пароль
                        <IconButton
                            onClick={() => copyLinkToClipboard('password_not_set')}
                            title="Копировать ссылку"
                            aria-label="Копировать ссылку"
                        >
                            <LinkIcon aria-hidden="true" fontSize="large" />
                        </IconButton>
                    </Typography>
                    <ul className={classes['support__list']}>
                        <li>
                            Если вы регистрировались через кнопку «Вконтакте», Google или Mail.Ru,
                            то пароль не задан, и его нужно задать, чтобы иметь возможность входить
                            в приложение. Придумайте пароль и введите его в соответствующее поле.
                        </li>
                    </ul>

                    <Typography variant="h2">Подписка</Typography>
                    <Typography
                        variant="h3"
                        id="trial_period"
                        className={classes['support__question']}
                    >
                        Я зарегистрировался, но за 2 недели пробного доступа не успел попробовать
                        приложение
                        <IconButton
                            onClick={() => copyLinkToClipboard('trial_period')}
                            title="Копировать ссылку"
                            aria-label="Копировать ссылку"
                        >
                            <LinkIcon aria-hidden="true" fontSize="large" />
                        </IconButton>
                    </Typography>
                    <ul className={classes['support__list']}>
                        <li>
                            Напишите нам на почту{' '}
                            <a href="mailto:info@wattattack.com">info@wattattack.com</a>, мы
                            рассмотрим вашу заявку и продлим пробный доступ.
                        </li>
                    </ul>

                    <Typography
                        variant="h3"
                        id="cancel_subscription"
                        className={classes['support__question']}
                    >
                        Я хочу отменить подписку
                        <IconButton
                            onClick={() => copyLinkToClipboard('cancel_subscription')}
                            title="Копировать ссылку"
                            aria-label="Копировать ссылку"
                        >
                            <LinkIcon aria-hidden="true" fontSize="large" />
                        </IconButton>
                    </Typography>
                    <ul className={classes['support__list']}>
                        <li>
                            Для этого в личном кабинете нажмите кнопку «<b>Отменить подписку</b>».
                            Доступ к приложению останется до конца оплаченного периода, а
                            автоматическое списание денег с карты прекратится. Возобновить подписку
                            можно в любой момент до конца срока её действия, нажав в личном кабинете
                            на кнопку «<b>Возобновить подписку</b>».
                        </li>
                    </ul>

                    <Typography
                        variant="h3"
                        id="payment_failed"
                        className={classes['support__question']}
                    >
                        У меня есть активная подписка, но очередная оплата не прошла
                        <IconButton
                            onClick={() => copyLinkToClipboard('payment_failed')}
                            title="Копировать ссылку"
                            aria-label="Копировать ссылку"
                        >
                            <LinkIcon aria-hidden="true" fontSize="large" />
                        </IconButton>
                    </Typography>
                    <ul className={classes['support__list']}>
                        <li>
                            Попытка оплаты подписки происходит несколько раз в день окончания
                            подписки. Если оплата не прошла, то ещё 2 дня будут предприняты попытки
                            выполнить списание. Если оплата не прошла, потому что на карте не было
                            достаточной суммы, то следует пополнить карту. Если же на карте стоял
                            запрет на выполнение автоматических списаний, то его нужно снять. В
                            остальных случаях мы можем только ещё раз попробовать.
                        </li>
                    </ul>

                    <Typography
                        variant="h3"
                        id="payment_failed_2"
                        className={classes['support__question']}
                    >
                        Моя активная подписка кончилась из-за невозможности списать с карты деньги,
                        мне пришло письмо, что будет выполнена ещё попытка, но я не хочу продлевать
                        подписку
                        <IconButton
                            onClick={() => copyLinkToClipboard('payment_failed_2')}
                            title="Копировать ссылку"
                            aria-label="Копировать ссылку"
                        >
                            <LinkIcon aria-hidden="true" fontSize="large" />
                        </IconButton>
                    </Typography>
                    <ul className={classes['support__list']}>
                        <li>
                            В таком случае напишите нам на{' '}
                            <a href="mailto:info@wattattack.com">info@wattattack.com</a>.
                        </li>
                    </ul>

                    <Typography
                        variant="h3"
                        id="change_card"
                        className={classes['support__question']}
                    >
                        Как мне сменить платёжную карту?
                        <IconButton
                            onClick={() => copyLinkToClipboard('change_card')}
                            title="Копировать ссылку"
                            aria-label="Копировать ссылку"
                        >
                            <LinkIcon aria-hidden="true" fontSize="large" />
                        </IconButton>
                    </Typography>
                    <ul className={classes['support__list']}>
                        <li>
                            Сейчас самостоятельно сменить карту нельзя, но мы скоро добавим этот
                            функционал в личный кабинет. Для смены карты напишите нам на{' '}
                            <a href="mailto:info@wattattack.com">info@wattattack.com</a>.
                        </li>
                    </ul>

                    <Typography variant="h2">Тренировки</Typography>
                    <Typography
                        variant="h3"
                        id="fit_file_location"
                        className={classes['support__question']}
                    >
                        Где мне найти fit-файл с результатами заезда?
                        <IconButton
                            onClick={() => copyLinkToClipboard('fit_file_location')}
                            title="Копировать ссылку"
                            aria-label="Копировать ссылку"
                        >
                            <LinkIcon aria-hidden="true" fontSize="large" />
                        </IconButton>
                    </Typography>
                    <ul className={classes['support__list']}>
                        <li>
                            Платформа Windows:
                            <br />
                            <code>
                                C:\Users\­&lt;Имя_пользователя&gt;\­AppData\Local\­WattAttack\Saved
                            </code>
                            <br />
                            Папка AppData является скрытой, поэтому нужно включить в Проводнике
                            отображение скрытых папок. Fit-файлы можно отсортировать по дате, чтобы
                            понять какое имя файла соответствует последней тренировке.
                        </li>
                        <li>
                            Платформа MacOS:
                            <br />
                            <code>
                                /Users/­&lt;Имя_пользователя&gt;/­Library/­Containers/­com.watt­attacktest/­Data/­Library/­Application­Support/­Epic/Watt­Attack/­Saved/
                            </code>
                            <br />
                        </li>
                    </ul>

                    <Typography
                        variant="h3"
                        id="strava_integration"
                        className={classes['support__question']}
                    >
                        Есть ли интеграция со Strava?
                        <IconButton
                            onClick={() => copyLinkToClipboard('strava_integration')}
                            title="Копировать ссылку"
                            aria-label="Копировать ссылку"
                        >
                            <LinkIcon aria-hidden="true" fontSize="large" />
                        </IconButton>
                    </Typography>
                    <ul className={classes['support__list']}>
                        <li>
                            Да, интеграция есть. Чтобы включить автоматическое сохранение тренировок
                            в Strava войдите в личный кабинет по ссылке{' '}
                            <a href="https://wattattack.com/cabinet#profile">
                                https://wattattack.com/­cabinet#profile
                            </a>{' '}
                            и разделе «Подключенные сервисы» нажмите на кнопку Connect with Strava.
                            В этот момент у вас должен быть включен VPN, потому что Strava блокирует
                            пользователей из России. На открывшейся странице Strava разрешите
                            приложению WattAttack подключение. После этого заезды при завершении
                            будут автоматически синхронизироваться с сервисом Strava (больше VPN не
                            нужен).
                        </li>
                    </ul>

                    <Typography
                        variant="h3"
                        id="import_from_zwo"
                        className={classes['support__question']}
                    >
                        Как мне загрузить тренировку из своего ZWO файла?
                        <IconButton
                            onClick={() => copyLinkToClipboard('import_from_zwo')}
                            title="Копировать ссылку"
                            aria-label="Копировать ссылку"
                        >
                            <LinkIcon aria-hidden="true" fontSize="large" />
                        </IconButton>
                    </Typography>
                    <ul className={classes['support__list']}>
                        <li>
                            Платформа Windows:
                            <br />
                            поместите файл в папку
                            <br />
                            <code>
                                C:\Users\­&lt;Имя_пользователя&gt;\­AppData\­Local\­Watt­Attack­\Saved\Zwift
                            </code>
                            <br />
                            Папка AppData является скрытой, поэтому нужно включить в Проводнике
                            отображение скрытых папок. Fit-файлы можно отсортировать по дате, чтобы
                            понять какое имя файла соответствует последней тренировке.
                        </li>
                        <li>
                            Платформа MacOS:
                            <br />
                            поместите файл в папку
                            <br />
                            <code>
                                /Users/­&lt;Имя_пользователя&gt;/­Library/­Containers/­com.watt­attack­test/­Data/Library/­Application­Support/­Epic/­WattAttack/Saved/Zwift
                            </code>
                        </li>
                        <li>
                            Платформа Android:
                            <br />
                            поместите файл в папку
                            <br />
                            <code>Android\data­\com.watt­attack\­filesZwift\</code>
                        </li>
                    </ul>
                </Box>
            </CustomContainer>
        </section>
    );
}

export default Support;
