import AppLayout from '../../layouts/AppLayout/AppLayout';
import MainFooter from '../../Components/MainFooter/MainFooter';
import MainHeader from '../../Components/MainHeader/MainHeader';
import SubscriptionSupport from '../../Components/SubscriptionSupport/SubscriptionSupport';

function SubscriptionSupportPage() {
    return (
        <AppLayout>
            <MainHeader variant="cabinet" />
            <main>
                <SubscriptionSupport />
            </main>
            <MainFooter />
        </AppLayout>
    );
}

export default SubscriptionSupportPage;
