import { useFormik } from 'formik';
import { FormattedMessage, useIntl } from 'react-intl';
import { useValidationSchema } from '../../../../../CustomHooks/useValidationSchema';
import { useAlertContext } from '../../../../Context/AlertContext';
import { useState } from 'react';
import UserService from '../../../../../services/UserService';
import * as yup from 'yup';
import TextField from '@mui/material/TextField';
import CustomContainer from '../../../CustomContainer/CustomContainer';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import TextFieldWrapper from '../../../TextFieldWrapper/TextFieldWrapper';
import CircularProgressWrapper from '../../../CircularProgressWrapper/CircularProgressWrapper';
import classes from './RecoveryPasswordForm.module.css';

const RecoverPasswordForm = () => {
    const intl = useIntl();
    const { emailSchema } = useValidationSchema();
    const showAlert = useAlertContext();

    const [showErrors, setShowErrors] = useState(false);
    const handleClickShowErrors = () => {
        setShowErrors(true);
    };

    const formik = useFormik({
        initialValues: {
            email: ''
        },
        onSubmit: async (values) => {
            try {
                await UserService.sendRecoveryPasswordLink(values);

                showAlert(
                    intl.formatMessage({
                        defaultMessage:
                            'Ссылка на восстановление пароля отправлена на вашу электронную почту.',
                        id: 'Register_passwordRecoverySuccess',
                        description: 'форма регистрации'
                    }),
                    'success'
                );
            } catch (error) {
                showAlert(
                    `${intl.formatMessage({
                        defaultMessage: 'Произошла ошибка. Попробуйте позже.',
                        id: 'Register_passwordRecoveryError',
                        description: 'форма регистрации'
                    })} ${error}`,
                    'error'
                );
            }
        },
        validationSchema: yup.object({
            email: emailSchema
        })
    });

    const { isSubmitting } = formik;

    return (
        <section className={classes['recover-password']} aria-labelledby="recovery-page-title">
            <CustomContainer>
                <Typography
                    variant="h1"
                    component="h1"
                    color="primary"
                    id="recovery-page-title"
                    className={classes['recover-password__title']}
                >
                    <FormattedMessage
                        defaultMessage="Восстановить пароль"
                        id="RecoverPassword_QgL8R7"
                        description="форма восстановления пароля"
                    />
                </Typography>
                <form
                    onSubmit={formik.handleSubmit}
                    className={classes['recover-password__form']}
                    aria-label={intl.formatMessage({
                        description: 'Форма восстановления пароля',
                        id: 'Register_recoverFormTitle',
                        defaultMessage: 'форма регистрации'
                    })}
                >
                    <TextFieldWrapper
                        errorText={formik.errors.email}
                        errorId="emailError"
                        showErrors={showErrors}
                    >
                        <TextField
                            type="email"
                            name="email"
                            label={intl.formatMessage({
                                defaultMessage: 'E-mail',
                                id: 'Login_ZJKmL5',
                                description: 'форма входа'
                            })}
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.email}
                            error={showErrors && !!formik.errors.email}
                            inputProps={{
                                'aria-describedby': 'emailError'
                            }}
                        />
                    </TextFieldWrapper>

                    <CircularProgressWrapper isSubmitting={isSubmitting} fullWidth>
                        <Button
                            fullWidth
                            variant="contained"
                            type="submit"
                            size="large"
                            onClick={handleClickShowErrors}
                            disabled={isSubmitting}
                        >
                            {intl.formatMessage({
                                defaultMessage: 'Восстановить',
                                id: 'RecoverPassword_lWz58F',
                                description: 'форма восстановления пароля'
                            })}
                        </Button>
                    </CircularProgressWrapper>
                </form>
            </CustomContainer>
        </section>
    );
};

export default RecoverPasswordForm;
