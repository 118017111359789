import Button from '@mui/material/Button';
import classes from '../../Cabinet.module.css';

type CabinetSubscriptionOverdueProps = {
    onUnsubscribe: () => void;
};

function CabinetSubscriptionOverdue({ onUnsubscribe }: CabinetSubscriptionOverdueProps) {
    return (
        <div>
            <p className={classes['cabinet-tabs__text']}>
                По вашей подписке просрочена оплата. Попытка автоматического списания будет
                предпринята ещё несколько раз. Но вы можете отменить подписку.
            </p>
            <Button sx={{ mt: 2 }} variant="contained" color="warning" onClick={onUnsubscribe}>
                Отменить подписку
            </Button>
        </div>
    );
}

export default CabinetSubscriptionOverdue;
