function CabinetSubscriptionLFT() {
    return (
        <div>
            <p>
                Тарифный план: <b>Пожизненная подписка</b>
            </p>
            <p>Спасибо вам за поддержку!</p>
        </div>
    );
}

export default CabinetSubscriptionLFT;
