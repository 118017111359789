import CabinetInvitesBuyGiftForm from './CabinetInvitesBuyGiftForm/CabinetInvitesBuyGiftForm';
import CabinetInvitesUserPromoCodes from './CabinetInvitesUserPromoCodes/CabinetInvitesUserPromoCodes';
import classes from '../Cabinet.module.css';
import { max } from 'date-fns';

function CabinetInvites() {
    return (
        <div>
            <p className={classes['cabinet-tabs__text']}>
                Вы можете купить подписку в подарок другу! После оплаты вы получите промо-код со
                100% скидкой на выбранное при оплате количество месяцев. Промо-код придёт на ваш
                адрес электронной почты и отобразится в личном кабинете.
            </p>
            <div style={{ maxWidth: '500px' }}>
                <CabinetInvitesBuyGiftForm />
            </div>
            <br />
            <br />
            <br />
            <CabinetInvitesUserPromoCodes />
        </div>
    );
}

export default CabinetInvites;
