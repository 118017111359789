import { observer } from 'mobx-react-lite';

import * as yup from 'yup';

import Button from '@mui/material/Button';
import TextField from '@mui/material/TextField';
import {
    FormControl,
    IconButton,
    InputAdornment,
    InputLabel,
    OutlinedInput,
    Typography
} from '@mui/material';
import CircularProgressWrapper from '../../../../CircularProgressWrapper/CircularProgressWrapper';
import Visibility from '@mui/icons-material/Visibility';
import VisibilityOff from '@mui/icons-material/VisibilityOff';

import { useStores } from '../../../../../../root-store-context';
import { useFormik } from 'formik';
import { FormattedMessage, useIntl } from 'react-intl';
import { useState } from 'react';
import { useAlertContext } from '../../../../../Context/AlertContext';
import { useValidationSchema } from '../../../../../../CustomHooks/useValidationSchema';
import TextFieldWrapper from '../../../../TextFieldWrapper/TextFieldWrapper';

import classes from '../../../Cabinet.module.css';

type CabinetProfileAccountEmailFormProps = {
    setIsEditorMode: (value: boolean) => void;
};

const CabinetProfileAccountEmailForm = ({
    setIsEditorMode
}: CabinetProfileAccountEmailFormProps) => {
    const intl = useIntl();
    const { user } = useStores();
    const userData = user.data;
    const showAlert = useAlertContext();
    const { emailSchema, currentPasswordSchema } = useValidationSchema();

    const [showErrors, setShowErrors] = useState(false);
    const handleClickShowErrors = () => {
        setShowErrors(true);
    };

    const [showPassword, setShowPassword] = useState(false);

    const handleClickShowPassword = () => setShowPassword((show) => !show);

    const handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
        event.preventDefault();
    };

    const formik = useFormik({
        initialValues: {
            email: userData?.email as string,
            currentPassword: ''
        },
        onSubmit: async (values) => {
            await user.updateEmail({
                email: values.email,
                currentPassword: values.currentPassword
            });

            if (user.state === 'ok') {
                showAlert(
                    intl.formatMessage({
                        defaultMessage: 'Email изменён. На него выслана ссылка для активации.',
                        id: 'Cabinet_emailChangeSuccess',
                        description: 'кабинет'
                    }),
                    'success'
                );
                formik.setFieldValue('currentPassword', '');
                setShowErrors(false);
                setIsEditorMode(false);
            } else {
                showAlert(
                    `${intl.formatMessage({
                        defaultMessage: 'Произошла ошибка:',
                        id: 'Cabinet_emailChangeError',
                        description: 'кабинет'
                    })} ${user.error}`,
                    'error'
                );
            }
        },
        validationSchema: yup.object({
            email: emailSchema,
            currentPassword: currentPasswordSchema
        })
    });

    const { isSubmitting } = formik;

    const handleCancelClick = () => {
        setIsEditorMode(false);
        formik.setFieldValue('email', userData?.email);
    };

    return (
        <div>
            <Typography variant="h4" component="h3" className={classes['cabinet-tabs__subtitle']}>
                <FormattedMessage
                    defaultMessage="Смена E-mail"
                    id="Cabinet_emailFormTitle"
                    description="кабинет"
                />
            </Typography>
            <form
                onSubmit={formik.handleSubmit}
                className={classes['cabinet-tabs__form']}
                aria-label={intl.formatMessage({
                    defaultMessage: 'Форма смены e-mail',
                    id: 'Cabinet_emailDataFormTitle',
                    description: 'кабинет'
                })}
            >
                <TextFieldWrapper
                    errorText={formik.errors.email}
                    errorId="emailError"
                    showErrors={showErrors}
                >
                    <TextField
                        id="cabinet-tabs-user-profile-email"
                        label="Email"
                        name="email"
                        value={formik.values.email}
                        onChange={formik.handleChange}
                        error={showErrors && !!formik.errors.email}
                        aria-describedby="emailError"
                    />
                </TextFieldWrapper>

                <TextFieldWrapper
                    errorText={formik.errors.currentPassword}
                    errorId="passwordError"
                    showErrors={showErrors}
                >
                    <FormControl variant="outlined">
                        <InputLabel htmlFor="currentPassword">
                            {intl.formatMessage({
                                defaultMessage: 'Введите пароль',
                                id: 'Register_cFLCWL',
                                description: 'форма регистрации'
                            })}
                        </InputLabel>
                        <OutlinedInput
                            type={showPassword ? 'text' : 'password'}
                            label={intl.formatMessage({
                                defaultMessage: 'Введите пароль',
                                id: 'Register_cFLCWL',
                                description: 'форма регистрации'
                            })}
                            name="currentPassword"
                            id="currentPassword"
                            autoCapitalize="off"
                            autoCorrect="off"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.currentPassword}
                            error={showErrors && !!formik.errors.currentPassword}
                            inputProps={{
                                'aria-describedby': 'passwordError'
                            }}
                            endAdornment={
                                <InputAdornment position="end">
                                    <IconButton
                                        aria-label={intl.formatMessage({
                                            defaultMessage: 'Показать пароль',
                                            id: 'Register_Gs+e1b7',
                                            description: 'форма'
                                        })}
                                        onClick={handleClickShowPassword}
                                        onMouseDown={handleMouseDownPassword}
                                        edge="end"
                                    >
                                        {showPassword ? <VisibilityOff /> : <Visibility />}
                                    </IconButton>
                                </InputAdornment>
                            }
                        />
                    </FormControl>
                </TextFieldWrapper>

                <div className={classes['cabinet-tabs__buttons']}>
                    <CircularProgressWrapper isSubmitting={isSubmitting} fullWidth>
                        <Button
                            fullWidth
                            type="submit"
                            variant="contained"
                            className={classes['cabinet-tabs__button']}
                            disabled={
                                isSubmitting ||
                                formik.values.email.toLowerCase().trim() === userData?.email
                            }
                            onClick={handleClickShowErrors}
                        >
                            <FormattedMessage
                                defaultMessage="Сохранить email"
                                id="Cabinet_EmailSave"
                                description="кабинет"
                            />
                        </Button>
                    </CircularProgressWrapper>
                    <Button
                        type="button"
                        color="info"
                        onClick={handleCancelClick}
                        variant="contained"
                        className={classes['cabinet-tabs__button']}
                    >
                        <FormattedMessage
                            defaultMessage="Отмена"
                            id="Cabinet_EmailCancel"
                            description="кабинет"
                        />
                    </Button>
                </div>
            </form>
        </div>
    );
};

export default observer(CabinetProfileAccountEmailForm);
