import CabinetProfileAccount from './CabinetProfileAccount/CabinetProfileAccount';
import CabinetProfilePerson from './CabinetProfilePerson/CabinetProfilePerson';
import CabinetProfileConnections from './CabinetProfileConnections/CabinetProfileConnections';

function CabinetProfile() {
    return (
        <>
            <CabinetProfileAccount />
            <CabinetProfilePerson />
            <CabinetProfileConnections />
        </>
    );
}

export default CabinetProfile;
