import { useAlertContext } from '../../../../Context/AlertContext';
import { useStores } from '../../../../../root-store-context';
import Button from '@mui/material/Button';
import IconButton from '@mui/material/IconButton';
import CreditCardIcon from '@mui/icons-material/CreditCard';
import HelpOutlineIcon from '@mui/icons-material/HelpOutline';
import classes from '../../Cabinet.module.css';
type CabinetSubscriptionCardInfoProps = {
    cardInfo: {
        lastFourDigits: number | null;
        type: string | null;
    };
};

function CabinetSubscriptionCardInfo({ cardInfo }: CabinetSubscriptionCardInfoProps) {
    const { subscription } = useStores();
    const showAlert = useAlertContext();
    const handleUpdateSubscription = async () => {
        try {
            await subscription.updateCard();

            const paymentUrl = subscription.data?.paymentUrl;

            if (paymentUrl) {
                window.location.href = paymentUrl;
            } else {
                showAlert('Произошла ошибка', 'error');
            }
        } catch (error) {
            console.error('Ошибка при обновлении подписки:', error);
            showAlert('Произошла ошибка', 'error');
        }
    };

    return (
        <div>
            <p style={{ marginBlockEnd: '5px' }}>
                Ваша платёжная карта:{' '}
                <span style={{ display: 'inline-flex', alignItems: 'center', gap: '5px' }}>
                    <b>
                        {cardInfo.type} *{cardInfo.lastFourDigits}
                    </b>{' '}
                    <CreditCardIcon />
                </span>
            </p>
            <Button size="small" variant="contained" color="info" onClick={handleUpdateSubscription}>
                Сменить карту 1₽
            </Button>{' '}
            <p className={classes['cabinet-tabs__text']}>Для смены карты нужно заплатить 1 рубль для подтверждения новой карты.</p>
            {/* <IconButton aria-label="Подробнее о платёжной карте">
                <HelpOutlineIcon />{' '}
            </IconButton> */}
        </div>
    );
}

export default CabinetSubscriptionCardInfo;
