import AppLayout from '../../layouts/AppLayout/AppLayout';
import MainFooter from '../../Components/MainFooter/MainFooter';
import MainHeader from '../../Components/MainHeader/MainHeader';
import Support from '../../Components/Support/Support';

function SupportPage() {
    return (
        <AppLayout>
            <MainHeader variant="cabinet" />
            <main>
                <Support />
            </main>
            <MainFooter />
        </AppLayout>
    );
}

export default SupportPage;
