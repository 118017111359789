import { useEffect, useState } from 'react';
import { observer } from 'mobx-react-lite';
import { defineMessages, useIntl } from 'react-intl';
import { visuallyHidden } from '@mui/utils';
import { useStores } from '../../../../root-store-context';
import { useTheme } from '@mui/material';
import { useViewport } from '../../../Context/ViewPortContext';
import { useLocation } from 'react-router-dom';
import useMediaQuery from '@mui/material/useMediaQuery';
import Container from '@mui/material/Container';
import Toolbar from '@mui/material/Toolbar';
import Box from '@mui/material/Box';
import Drawer from '@mui/material/Drawer';
import Typography from '@mui/material/Typography';
import MobileMainMenu from '../../MobileMainMenu/MobileMainMenu';
import CloseIcon from '@mui/icons-material/Close';
import MenuIcon from '@mui/icons-material/Menu';
import IconButton from '@mui/material/IconButton';
import TelegramIcon from '@mui/icons-material/Telegram';
import LocaleSwitcher from '../../LocaleSwitcher/LocaleSwitcher';
import { WattAttackLogo } from '../../WattAttackLogo';
import CabinetMainMenu from '../CabinetMainMenu/CabinetMainMenu';
import CabinetLogOut from '../CabinetLogOut/CabinetLogOut';
import { NAVIGATION_ROUTES } from '../../../../const';
import classes from './CabinetMainNavigation.module.css';

export const messages = defineMessages({
    openMenu: {
        id: 'mainNavigation.openMenu',
        defaultMessage: 'Открыть меню',
        description: 'Главная навигация'
    },
    closeMenu: {
        id: 'mainNavigation.closeMenu',
        defaultMessage: 'Закрыть меню',
        description: 'Главная навигация'
    }
});

function CabinetMainNavigation() {
    const intl = useIntl();
    const theme = useTheme();
    const { isMobile } = useViewport();
    const isTablet = useMediaQuery(theme.breakpoints.down('lg'));
    const { user } = useStores();
    const location = useLocation();

    const [isDrawerOpen, setIsDrawerOpen] = useState(false);

    const openDrawer = () => {
        setIsDrawerOpen(true);
    };

    const closeDrawer = () => {
        setIsDrawerOpen(false);
    };

    useEffect(() => {
        const sectionElement = document.getElementById(
            location.hash.slice(1, location.hash.length)
        );
        const offset = 128;
        if (sectionElement) {
            const targetScroll = sectionElement.offsetTop - offset;
            sectionElement.scrollIntoView({ behavior: 'smooth' });
            window.scrollTo({
                top: targetScroll
            });
        }
    }, [location.hash]);

    return (
        <Container maxWidth="xl">
            <Toolbar component="nav" className={classes['cabinet-main-navigation']}>
                {!isMobile && (
                    <a
                        className={classes['cabinet-main-navigation__logo']}
                        href={NAVIGATION_ROUTES.root}
                    >
                        {isTablet ? (
                            <WattAttackLogo variant="short-light" width="40px" height="30px" />
                        ) : (
                            <WattAttackLogo variant="light" width="150px" height="27px" />
                        )}
                    </a>
                )}

                {!isMobile && (
                    <CabinetMainMenu className={classes['cabinet-main-navigation__main-menu']} />
                )}

                {isMobile && (
                    <a
                        className={classes['cabinet-main-navigation__mobile-logo']}
                        href={NAVIGATION_ROUTES.root}
                    >
                        <WattAttackLogo variant="light" />
                    </a>
                )}

                <div className={classes['cabinet-main-navigation__actions']}>
                    <LocaleSwitcher
                        className={classes['cabinet-main-navigation__locale-switcher']}
                    />

                    {user.isLogined && !isMobile && (
                        <CabinetLogOut className={classes['cabinet-main-navigation__log-out']} />
                    )}
                </div>

                <IconButton
                    edge="end"
                    color="inherit"
                    aria-label={intl.formatMessage(messages.openMenu)}
                    onClick={openDrawer}
                    className={classes['cabinet-main-navigation__burger-button']}
                >
                    <MenuIcon
                        className={classes['cabinet-main-navigation__burger-button']}
                        fontSize="large"
                    />
                </IconButton>
            </Toolbar>

            <Drawer anchor="right" open={isDrawerOpen} onClose={closeDrawer}>
                <Box component="nav" className={classes['cabinet-main-navigation__mobile-nav']}>
                    <Toolbar className={classes['cabinet-main-navigation__mobile-toolbar']}>
                        <IconButton
                            edge="start"
                            color="inherit"
                            aria-label={intl.formatMessage(messages.closeMenu)}
                            onClick={closeDrawer}
                            className={classes['cabinet-main-navigation__burger-close']}
                        >
                            <CloseIcon fontSize="large" />
                        </IconButton>

                        <WattAttackLogo
                            variant="dark"
                            width="127"
                            height="24"
                            className={`${classes['cabinet-main-navigation__logo']} ${classes['cabinet-main-navigation__logo--drawer']}`}
                        />
                        {user.isLogined && (
                            <Typography
                                variant="body1"
                                className={classes['cabinet-main-navigation__email']}
                            >
                                {user.data?.email}
                            </Typography>
                        )}

                        <MobileMainMenu clickHandler={closeDrawer} />

                        {intl.locale === 'ru' && (
                            <a
                                rel="noreferrer"
                                href="https://t.me/wattattack"
                                target="_blank"
                                className={classes['cabinet-main-navigation__telegram-mobile-link']}
                                title="Телеграм канал"
                            >
                                <Typography sx={visuallyHidden}>Телеграм канал</Typography>
                                <TelegramIcon fontSize="large" />
                            </a>
                        )}
                    </Toolbar>
                </Box>
            </Drawer>
        </Container>
    );
}

export default observer(CabinetMainNavigation);
