import { Box, Button, IconButton } from '@mui/material';
import { useState } from 'react';
import { FormattedMessage } from 'react-intl';
import UserService from '../../../../services/UserService';
import CloseIcon from '@mui/icons-material/Close';
import classes from '../Cabinet.module.css';

function CabinetConfirmEmailMessage() {
    const [isActivationMessageShowed, setIsActivationMessageShowed] = useState(true);
    const [errorMessage, setErrorMessage] = useState('');

    const handleSendActivationClick = async () => {
        try {
            await UserService.sendActivationLink();
        } catch (error: any) {
            setErrorMessage(error.message);
        }
        setIsActivationMessageShowed(false);
    };

    return (
        <>
            {errorMessage && (
                <Box
                    typography="subtitle1"
                    className={`${classes['cabinet-tabs__activation-message']} ${classes['cabinet-tabs__activation-message--error']}`}
                >
                    <FormattedMessage
                        defaultMessage="При отправке ссылки на активацию произошла ошибка:"
                        id="Cabinet_EmailError"
                        description="кабинет"
                    />{' '}
                    {errorMessage}
                    <IconButton
                        onClick={() => {
                            setErrorMessage('');
                            setIsActivationMessageShowed(true);
                        }}
                        aria-label="Закрыть"
                        className={`${classes['cabinet-tabs__activation-close']} ${classes['cabinet-tabs__activation-close--error']}`}
                    >
                        <CloseIcon className={classes['cabinet-tabs__activation-icon']} />
                    </IconButton>
                </Box>
            )}

            {isActivationMessageShowed && (
                <Box typography="subtitle1" className={classes['cabinet-tabs__activation-message']}>
                    <FormattedMessage
                        defaultMessage="Email не подтверждён! Для подтверждения пройдите по ссылке из письма, которое получили при регистрации. Если по какой-то причине Вы не получили письмо, мы можем"
                        id="Cabinet_EmailConfirmation"
                        description="кабинет"
                    />{' '}
                    <Button
                        variant="text"
                        type="button"
                        className={classes['cabinet-tabs__activation-button']}
                        onClick={handleSendActivationClick}
                    >
                        <FormattedMessage
                            defaultMessage="отправить"
                            id="Cabinet_EmailSend"
                            description="кабинет"
                        />
                    </Button>{' '}
                    <FormattedMessage
                        defaultMessage="его ещё раз."
                        id="Cabinet_EmailAgain"
                        description="кабинет"
                    />{' '}
                    <FormattedMessage
                        defaultMessage="Или обратитесь в службу поддержки:"
                        id="Cabinet_EmailSupport"
                        description="кабинет"
                    />{' '}
                    <a
                        className={classes['cabinet-tabs__support-link']}
                        href="mailto:info@wattattack.com"
                    >
                        info@wattattack.com
                    </a>
                    <IconButton
                        onClick={() => setIsActivationMessageShowed(false)}
                        aria-label="Закрыть"
                        className={classes['cabinet-tabs__activation-close']}
                    >
                        <CloseIcon className={classes['cabinet-tabs__activation-icon']} />
                    </IconButton>
                </Box>
            )}
        </>
    );
}

export default CabinetConfirmEmailMessage;
