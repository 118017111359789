import AppLayout from '../../layouts/AppLayout/AppLayout';
import MainHeader from '../../Components/MainHeader/MainHeader';
import MainFooter from '../../Components/MainFooter/MainFooter';
import CustomContainer from '../../Components/CustomContainer/CustomContainer';
import Typography from '@mui/material/Typography';
import Link from '@mui/material/Link';
import { FormattedMessage } from 'react-intl';
import { NAVIGATION_ROUTES } from '../../../const';
import classes from './ErrorPage.module.css';

function ErrorPage() {
    return (
        <AppLayout>
            <MainHeader variant="cabinet" />
            <main>
                <section className={classes['error-page']} aria-labelledby="error-page-title">
                    <CustomContainer>
                        <Typography
                            variant="h1"
                            color="primary"
                            id="error-page-title"
                            className={classes['error-page__title']}
                        >
                            <FormattedMessage
                                defaultMessage="Ничего не найдено"
                                id="ErrorPage_Title"
                                description="404"
                            />
                        </Typography>
                        <Typography
                            variant="h2"
                            component="p"
                            className={classes['error-page__subtitle']}
                        >
                            <FormattedMessage
                                defaultMessage="Ошибка 404"
                                id="ErrorPage_SubTitle"
                                description="404"
                            />
                        </Typography>
                        <Typography variant="subtitle1" component="p">
                            <FormattedMessage
                                defaultMessage="Попробуйте вернуться на"
                                id="ErrorPage_Message"
                                description="404"
                            />{' '}
                            <Link href={NAVIGATION_ROUTES.root}>
                                <FormattedMessage
                                    defaultMessage="главную страницу."
                                    id="ErrorPage_Link"
                                    description="404"
                                />
                            </Link>
                        </Typography>
                    </CustomContainer>
                </section>
            </main>
            <MainFooter />
        </AppLayout>
    );
}

export default ErrorPage;
