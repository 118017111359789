import { ReactElement } from 'react';
import { useScrollContext } from '../../Context/ScrollContext';
import { Slide, useScrollTrigger } from '@mui/material';
import AppBar from '@mui/material/AppBar';
import MainNavigation from '../MainNavigation/MainNavigation';
import CabinetMainNavigation from '../Cabinet/CabinetMainNavigation/CabinetMainNavigation';

type MainHeaderProps = {
    variant?: 'landing' | 'cabinet';
};

function HideOnScroll(props: { children: ReactElement }) {
    const { children } = props;
    const trigger = useScrollTrigger();

    return (
        <Slide appear={false} direction="down" in={!trigger}>
            {children}
        </Slide>
    );
}

function MainHeader({ variant = 'landing' }: MainHeaderProps) {
    const { isScrolled } = useScrollContext();
    const appBarColor =
        variant === 'cabinet' ? 'secondary' : isScrolled ? 'default' : 'transparent';
    const appBarStyle =
        variant === 'landing' && !isScrolled
            ? {
                  background: `linear-gradient(to bottom, rgba(0,0,0,0.5), transparent)`
              }
            : {};

    return (
        <>
            <HideOnScroll>
                <AppBar
                    style={isScrolled ? {} : appBarStyle}
                    color={appBarColor}
                    elevation={isScrolled ? 4 : 0}
                >
                    {variant === 'cabinet' ? <CabinetMainNavigation /> : <MainNavigation />}
                </AppBar>
            </HideOnScroll>
        </>
    );
}

export default MainHeader;
