export const APP_DOWNLOAD_LINK_WIN =
    'https://download.wattattack.ru/release/WattAttack_installer.exe';
export const APP_DOWNLOAD_LINK_MAC =
    'https://download.wattattack.ru/release/WattAttack-Shipping.pkg';
export const APP_DOWNLOAD_LINK_AND =
    'https://play.google.com/store/apps/details?id=com.wattattack&hl=ru';

export const CURRENT_APP_VERSION = {
    windows: { version: '1.09', size: '290' },
    macos: { version: '1.09', size: '190' },
    ios: { version: '1.09', size: '90' },
    android: { version: '1.09', size: '90' }
} as const;

export const NAVIGATION_ROUTES = {
    root: '/',
    login: '/login',
    logout: '/logout',
    cabinet: '/cabinet',
    cabinet_subscription: '/cabinet#subscription',
    registration: '/registration',
    password_recovery: '/user/recovery',
    password_reset: '/user/:id/change/:token',
    how_to_start: '/#howToStart',
    maps: '/#maps',
    workouts: '/#workouts',
    about: '/#about',
    subscription: '/#subscription',
    help: '/support',
    download: '/#download',
    user_activate: '/user/activate',
    activities: '/cabinet/activities',
    privacy_policy: '/privacy-policy',
    app_pay: '/app/pay',
    subscription_support: '/subscription/support',
    support: '/support',
    subscription_promo: '/cabinet/promo24'
} as const;

export const DEFAULT_LOCALE = 'ru';
export const SUPPORTED_LOCALES = ['ru', 'en'];
export const LOCALE_COOKIE_NAME = 'locale';

export const DEFAULT_LOGO_SIZE = {
    width: '487',
    height: '85'
} as const;

export const OAUTH_PROVIDERS = {
    vk: 'vk',
    mailRu: 'mailRu',
    google: 'google'
} as const;

export type OAuthProviders = keyof typeof OAUTH_PROVIDERS;

export const MESSAGE_TRANSITION_TIMEOUT = 500;

export const SCROLL_OFFSET = 128;

export const SUBSCRIPTION_TYPE_MAP: Record<string, string> = {
    TRIAL: 'Пробная подписка',
    MTH: 'Месяц',
    MTH_PROMO_24: 'Месяц Промо',
    YEAR: 'Год',
    TEST: 'Тест',
    GIFT: 'Подарочная подписка',
    LFT: 'Пожизненная подписка'
};
