import { FormattedMessage } from 'react-intl';
import Button from '@mui/material/Button';
import SvgIcon, { SvgIconProps } from '@mui/material/SvgIcon';

import { buildApiUrl } from '../../../../services/api';

import classes from './VKAuthButton.module.css';

function VKIcon(props: SvgIconProps) {
    return (
        <SvgIcon {...props} sx={{ width: '30px', height: '30px' }}>
            <svg
                width="36"
                height="36"
                viewBox="0 -1 28 28"
                fill="none"
                xmlns="http://www.w3.org/2000/svg"
            >
                <path
                    d="M3 13.56c0-4.98 0-7.47 1.55-9.01C6.09 3 8.58 3 13.56 3h.88c4.98 0 7.47 0 9.01 1.55C25 6.09 25 8.58 25 13.56v.88c0 4.98 0 7.47-1.55 9.01C21.91 25 19.42 25 14.44 25h-.88c-4.98 0-7.47 0-9.01-1.55C3 21.91 3 19.42 3 14.44v-.88Z"
                    fill="#fff"
                />
                <path
                    d="M14.7 18.9c-5.02 0-7.88-3.45-8-9.2h2.52c.08 4.22 1.93 6 3.4 6.37V9.7H15v3.64c1.45-.16 2.98-1.82 3.49-3.64h2.37a7.02 7.02 0 0 1-3.22 4.59c1.17.55 3.05 2 3.77 4.61h-2.6a4.54 4.54 0 0 0-3.8-3.29v3.29h-.3Z"
                    fill="#07F"
                />
            </svg>
        </SvgIcon>
    );
}

const VKAuthButton = () => {
    return (
        <>
            <Button
                href={`${buildApiUrl('/connection/oauth/login')}?redirectUrl=${encodeURIComponent(
                    document.location.href
                )}&appId=vk`}
                startIcon={<VKIcon />}
                fullWidth
                size="large"
                variant="contained"
                className={classes['vk-auth-button']}
            >
                <FormattedMessage
                    defaultMessage="Войти с VK ID"
                    id="Cabinet_EnterVK"
                    description="кабинет"
                />
            </Button>
        </>
    );
};

export default VKAuthButton;
