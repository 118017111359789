import { useState } from 'react';
import { observer } from 'mobx-react-lite';
import { useScrollContext } from '../../Context/ScrollContext';
import { useViewport } from '../../Context/ViewPortContext';
import { defineMessages, useIntl } from 'react-intl';
import { visuallyHidden } from '@mui/utils';
import { Box, Drawer, IconButton, Toolbar, Typography, useTheme } from '@mui/material';
import { useStores } from '../../../root-store-context';
import MenuIcon from '@mui/icons-material/Menu';
import CloseIcon from '@mui/icons-material/Close';
import LocaleSwitcher from '../LocaleSwitcher/LocaleSwitcher';
import MainMenu from '../MainMenu/MainMenu';
import Container from '@mui/system/Container';
import TelegramIcon from '@mui/icons-material/Telegram';
import useMediaQuery from '@mui/material/useMediaQuery';
import { WattAttackLogo } from '../WattAttackLogo';
import MobileMainMenu from '../MobileMainMenu/MobileMainMenu';
import { NAVIGATION_ROUTES } from '../../../const';
import classes from './MainNavigation.module.css';

const messages = defineMessages({
    openMenu: {
        id: 'mainNavigation.openMenu',
        defaultMessage: 'Открыть меню',
        description: 'Главная навигация'
    },
    closeMenu: {
        id: 'mainNavigation.closeMenu',
        defaultMessage: 'Закрыть меню',
        description: 'Главная навигация'
    }
});

function MainNavigation() {
    const { isScrolled } = useScrollContext();
    const { isMobile } = useViewport();
    const theme = useTheme();
    const intl = useIntl();
    const color = isScrolled ? theme.palette.secondary.main : theme.palette.common.white;
    const { user } = useStores();

    const isTablet = useMediaQuery(theme.breakpoints.down('lg'));

    const [isDrawerOpen, setIsDrawerOpen] = useState(false);

    const openDrawer = () => {
        setIsDrawerOpen(true);
    };

    const closeDrawer = () => {
        setIsDrawerOpen(false);
    };

    return (
        <Container maxWidth="xl">
            <Toolbar component="nav" className={classes['main-navigation']}>
                {!isMobile && isScrolled && (
                    <a className={classes['main-navigation__logo']} href={NAVIGATION_ROUTES.root}>
                        {isTablet ? (
                            <WattAttackLogo variant="short-dark" width="40px" height="30px" />
                        ) : (
                            <WattAttackLogo variant="dark" width="150px" height="27px" />
                        )}
                    </a>
                )}

                {!isMobile && (
                    <MainMenu
                        className={classes['main-navigation__main-menu']}
                        isScrolled={isScrolled}
                    />
                )}

                <LocaleSwitcher
                    className={classes['main-navigation__locale-switcher']}
                    isScrolled={isScrolled}
                />

                {isScrolled && isMobile && (
                    <a
                        className={classes['main-navigation__mobile-logo']}
                        href={NAVIGATION_ROUTES.root}
                    >
                        <WattAttackLogo variant="dark" />
                    </a>
                )}

                <IconButton
                    edge="end"
                    color="inherit"
                    aria-label={intl.formatMessage(messages.openMenu)}
                    onClick={openDrawer}
                    className={classes['main-navigation__burger-button']}
                >
                    <MenuIcon fontSize="large" htmlColor={color} />
                </IconButton>
            </Toolbar>
            <Drawer anchor="right" open={isDrawerOpen} onClose={closeDrawer}>
                <Box component="nav" className={classes['main-navigation__mobile-nav']}>
                    <Toolbar className={classes['main-navigation__mobile-toolbar']}>
                        <IconButton
                            edge="start"
                            color="inherit"
                            aria-label={intl.formatMessage(messages.closeMenu)}
                            onClick={closeDrawer}
                            className={classes['main-navigation__burger-close']}
                        >
                            <CloseIcon fontSize="large" />
                        </IconButton>
                        <WattAttackLogo
                            variant="dark"
                            width="127"
                            height="24"
                            className={`${classes['main-navigation__logo']} ${classes['main-navigation__logo--drawer']}`}
                        />
                        {user.isLogined && (
                            <Typography
                                variant="body1"
                                className={classes['main-navigation__email']}
                            >
                                {user.data?.email}
                            </Typography>
                        )}
                        <MobileMainMenu clickHandler={closeDrawer} />
                        {intl.locale === 'ru' && (
                            <a
                                rel="noreferrer"
                                href="https://t.me/wattattack"
                                target="_blank"
                                className={classes['main-navigation__telegram-mobile-link']}
                                title="Телеграм канал"
                            >
                                <Typography sx={visuallyHidden}>Телеграм канал</Typography>
                                <TelegramIcon fontSize="large" />
                            </a>
                        )}
                    </Toolbar>
                </Box>
            </Drawer>
        </Container>
    );
}

export default observer(MainNavigation);
