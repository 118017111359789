import { useEffect, useState } from 'react';
import { buildApiUrl } from '../../../../../services/api';
import Box from '@mui/material/Box';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import RefreshIcon from '@mui/icons-material/Refresh';
import Table from '@mui/material/Table';
import TableHead from '@mui/material/TableHead';
import TableRow from '@mui/material/TableRow';
import TableCell from '@mui/material/TableCell';
import TableBody from '@mui/material/TableBody';

export async function fetchPromoCodes() {
    try {
        const response = await fetch(buildApiUrl('/promocode/list'), {
            method: 'GET',
            headers: {
                'Content-Type': 'application/json'
            }
        });

        if (!response.ok) {
            throw new Error('Failed to fetch promo codes');
        }

        const data = await response.json();
        return data.promoCodes;
    } catch (error) {
        console.error('Failed to fetch promo codes:', error);
        return [];
    }
}

type PromoCode = {
    id: string;
    code: string;
    createdAt: string;
    usagePeriods: number;
    isUsed: boolean;
    usedBy: string | null;
};

function CabinetInvitesUserPromoCodes() {
    const [codes, setCodes] = useState<PromoCode[]>([]);

    const loadPaymentHistory = async () => {
        const promoCodes = await fetchPromoCodes();
        setCodes(promoCodes);
    };

    function formatDate(dateString: string): string {
        const date = new Date(dateString);
        return date.toLocaleDateString('ru-RU', {
            year: 'numeric',
            month: 'long',
            day: 'numeric'
        });
    }

    useEffect(() => {
        loadPaymentHistory();
    }, []);
    return (
        <Box mt={4}>
            <Typography variant="h3">Ваши промо-коды</Typography>
            {codes.length === 0 ? (
                <Typography>У вас ещё нет промо-кодов.</Typography>
            ) : (
                <>
                    <Button
                        startIcon={<RefreshIcon />}
                        variant="contained"
                        color="info"
                        onClick={loadPaymentHistory}
                        sx={{ marginBottom: 3, marginTop: 2 }}
                    >
                        Обновить список кодов
                    </Button>
                    <Table sx={{ display: { xs: 'block', sm: 'table' }, overflowX: 'auto' }}>
                        <TableHead>
                            <TableRow>
                                <TableCell>№</TableCell>
                                <TableCell>Промо-код</TableCell>
                                <TableCell>Дата создания</TableCell>
                                <TableCell>Количество месяцев</TableCell>
                                <TableCell>Статус</TableCell>
                                <TableCell>Пользователь</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {codes.map((code, index) => (
                                <TableRow key={code.id}>
                                    <TableCell>{index + 1}</TableCell>
                                    <TableCell>{code.code}</TableCell>
                                    <TableCell>{formatDate(code.createdAt)}</TableCell>
                                    <TableCell>{code.usagePeriods}</TableCell>
                                    <TableCell>
                                        {code.isUsed ? 'Принят' : 'Ещё не принят'}
                                    </TableCell>
                                    <TableCell>{code.usedBy || '—'}</TableCell>
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </>
            )}
        </Box>
    );
}

export default CabinetInvitesUserPromoCodes;
