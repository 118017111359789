import { useIntl } from 'react-intl';
import { useMediaQuery, useTheme } from '@mui/material';
import AppLayout from '../../layouts/AppLayout/AppLayout';
import MainHeader from '../../Components/MainHeader/MainHeader';
import MainHero from '../../Components/MainHero/MainHero';
import HowToStart from '../../Components/HowToStart/HowToStart';
import Tracks from '../../Components/Tracks/Tracks';
import Workouts from '../../Components/Workouts/Workouts';
import Competitions from '../../Components/Competitions/Competitions';
import About from '../../Components/About/About';
import Subscription from '../../Components/Subscription/Subscription';
import DownloadApp from '../../Components/DownloadApp/DownloadApp';
import FAQ from '../../Components/FAQ/FAQ';
import Feedback from '../../Components/Feedback/Feedback';
import MainFooter from '../../Components/MainFooter/MainFooter';
import FloatingTelegramButton from '../../Components/FloatingTelegramButton/FloatingTelegramButton';

function MainPage() {
    const intl = useIntl();
    const theme = useTheme();
    const isTablet = useMediaQuery(theme.breakpoints.down('md'));

    return (
        <AppLayout>
            <MainHeader variant="landing" />
            <main>
                <MainHero />
                <HowToStart />
                <Tracks />
                <Workouts />
                <Competitions />
                <About />
                <Subscription />
                <DownloadApp />
                {/* <FAQ /> */}
                <Feedback />
            </main>
            <MainFooter />
            {intl.locale === 'ru' && !isTablet && <FloatingTelegramButton />}
        </AppLayout>
    );
}

export default MainPage;
