import { observer } from 'mobx-react-lite';

import * as yup from 'yup';

import { FormattedMessage, useIntl } from 'react-intl';
import { useStores } from '../../../../../../root-store-context';
import { useFormik } from 'formik';
import { useState } from 'react';
import { useAlertContext } from '../../../../../Context/AlertContext';
import { useValidationSchema } from '../../../../../../CustomHooks/useValidationSchema';

import CircularProgressWrapper from '../../../../CircularProgressWrapper/CircularProgressWrapper';
import {
    FormControl,
    IconButton,
    InputAdornment,
    InputLabel,
    OutlinedInput,
    Typography,
    Button
} from '@mui/material';
import VisibilityOff from '@mui/icons-material/VisibilityOff';
import Visibility from '@mui/icons-material/Visibility';
import TextFieldWrapper from '../../../../TextFieldWrapper/TextFieldWrapper';

import classes from '../../../Cabinet.module.css';

type CabinetProfileAccountPasswordFormProps = {
    setIsEditorMode: (value: boolean) => void;
};

const CabinetProfileAccountPasswordForm = ({
    setIsEditorMode
}: CabinetProfileAccountPasswordFormProps) => {
    const intl = useIntl();
    const { user } = useStores();
    const showAlert = useAlertContext();
    const { currentPasswordSchema, passwordSchema, confirmPasswordSchema } = useValidationSchema();

    const [showErrors, setShowErrors] = useState(false);
    const handleClickShowErrors = () => {
        setShowErrors(true);
    };

    const [showPassword, setShowPassword] = useState(false);

    const handleClickShowPassword = () => setShowPassword((show) => !show);

    const handleMouseDownPassword = (event: React.MouseEvent<HTMLButtonElement>) => {
        event.preventDefault();
    };

    const formik = useFormik({
        initialValues: {
            currentPassword: '',
            password: '',
            confirmPassword: ''
        },
        onSubmit: async (values) => {
            await user.updatePassword({
                currentPassword: values.currentPassword,
                password: values.password
            });

            if (user.state === 'ok') {
                showAlert(
                    intl.formatMessage({
                        defaultMessage: 'Пароль изменён.',
                        id: 'Register_changeSuccess',
                        description: 'форма регистрации'
                    }),
                    'success'
                );
            } else {
                showAlert(
                    `${intl.formatMessage({
                        defaultMessage: 'Произошла ошибка:',
                        id: 'Register_changeError',
                        description: 'форма регистрации'
                    })} ${user.error}`,
                    'error'
                );
            }
        },
        validationSchema: yup.object({
            currentPassword: currentPasswordSchema,
            password: passwordSchema,
            confirmPassword: confirmPasswordSchema
        })
    });

    const { isSubmitting } = formik;

    return (
        <div>
            <Typography variant="h4" component="h3" className={classes['cabinet-tabs__subtitle']}>
                <FormattedMessage
                    defaultMessage="Смена пароля"
                    id="Cabinet_PasswordChangeTitle"
                    description="кабинет"
                />
            </Typography>
            <form
                onSubmit={formik.handleSubmit}
                className={classes['cabinet-tabs__form']}
                aria-label={intl.formatMessage({
                    defaultMessage: 'Форма смены пароля',
                    id: 'Cabinet_changePasswordFormTitle',
                    description: 'кабинет'
                })}
            >
                <TextFieldWrapper
                    errorText={formik.errors.currentPassword}
                    errorId="currentPasswordError"
                    showErrors={showErrors}
                >
                    <FormControl variant="outlined">
                        <InputLabel htmlFor="currentPassword">
                            {intl.formatMessage({
                                defaultMessage: 'Текущий пароль',
                                id: 'Cabinet_PasswordChangeCurrent',
                                description: 'кабинет'
                            })}
                        </InputLabel>
                        <OutlinedInput
                            type={showPassword ? 'text' : 'password'}
                            label={intl.formatMessage({
                                defaultMessage: 'Текущий пароль',
                                id: 'Cabinet_PasswordChangeCurrent',
                                description: 'кабинет'
                            })}
                            name="currentPassword"
                            id="currentPassword"
                            autoCapitalize="off"
                            autoCorrect="off"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.currentPassword}
                            error={showErrors && !!formik.errors.currentPassword}
                            inputProps={{
                                'aria-describedby': 'currentPasswordError'
                            }}
                            endAdornment={
                                <InputAdornment position="end">
                                    <IconButton
                                        aria-label={intl.formatMessage({
                                            defaultMessage: 'Показать пароль',
                                            id: 'Register_Gs+e1b7',
                                            description: 'форма'
                                        })}
                                        onClick={handleClickShowPassword}
                                        onMouseDown={handleMouseDownPassword}
                                        edge="end"
                                    >
                                        {showPassword ? <VisibilityOff /> : <Visibility />}
                                    </IconButton>
                                </InputAdornment>
                            }
                        />
                    </FormControl>
                </TextFieldWrapper>

                <TextFieldWrapper
                    helper={intl.formatMessage({
                        defaultMessage:
                            'Пароль должен содержать хотя бы одну цифру, строчную и заглавную букву и должен быть от 6 до 30 символов.',
                        id: 'Register_xwo634g',
                        description: 'форма регистрации'
                    })}
                    errorText={formik.errors.password}
                    errorId="passwordError"
                    showErrors={showErrors}
                >
                    <FormControl variant="outlined">
                        <InputLabel htmlFor="password">
                            {intl.formatMessage({
                                defaultMessage: 'Новый пароль',
                                id: 'Cabinet_PasswordChangeNew',
                                description: 'кабинет'
                            })}
                        </InputLabel>
                        <OutlinedInput
                            type={showPassword ? 'text' : 'password'}
                            label={intl.formatMessage({
                                defaultMessage: 'Новый пароль',
                                id: 'Cabinet_PasswordChangeNew',
                                description: 'кабинет'
                            })}
                            name="password"
                            id="password"
                            autoCapitalize="off"
                            autoCorrect="off"
                            autoComplete="new-password"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.password}
                            error={showErrors && !!formik.errors.password}
                            inputProps={{
                                'aria-describedby': 'passwordError'
                            }}
                            endAdornment={
                                <InputAdornment position="end">
                                    <IconButton
                                        aria-label={intl.formatMessage({
                                            defaultMessage: 'Показать пароль',
                                            id: 'Register_Gs+e1b7',
                                            description: 'форма'
                                        })}
                                        onClick={handleClickShowPassword}
                                        onMouseDown={handleMouseDownPassword}
                                        edge="end"
                                    >
                                        {showPassword ? <VisibilityOff /> : <Visibility />}
                                    </IconButton>
                                </InputAdornment>
                            }
                        />
                    </FormControl>
                </TextFieldWrapper>

                <TextFieldWrapper
                    errorText={formik.errors.confirmPassword}
                    errorId="confirmPassword"
                    showErrors={showErrors}
                >
                    <FormControl variant="outlined">
                        <InputLabel htmlFor="confirmPassword">
                            {intl.formatMessage({
                                defaultMessage: 'Подтверждение нового пароля',
                                id: 'Cabinet_PasswordChangeNewApprove',
                                description: 'кабинет'
                            })}
                        </InputLabel>
                        <OutlinedInput
                            type={showPassword ? 'text' : 'password'}
                            label={intl.formatMessage({
                                defaultMessage: 'Подтверждение нового пароля',
                                id: 'Cabinet_PasswordChangeNewApprove',
                                description: 'кабинет'
                            })}
                            name="confirmPassword"
                            id="confirmPassword"
                            autoCapitalize="off"
                            autoCorrect="off"
                            autoComplete="new-password"
                            onChange={formik.handleChange}
                            onBlur={formik.handleBlur}
                            value={formik.values.confirmPassword}
                            error={showErrors && !!formik.errors.confirmPassword}
                            inputProps={{
                                'aria-describedby': 'confirmPasswordError'
                            }}
                            endAdornment={
                                <InputAdornment position="end">
                                    <IconButton
                                        aria-label={intl.formatMessage({
                                            defaultMessage: 'Показать пароль',
                                            id: 'Register_Gs+e1b7',
                                            description: 'форма'
                                        })}
                                        onClick={handleClickShowPassword}
                                        onMouseDown={handleMouseDownPassword}
                                        edge="end"
                                    >
                                        {showPassword ? <VisibilityOff /> : <Visibility />}
                                    </IconButton>
                                </InputAdornment>
                            }
                        />
                    </FormControl>
                </TextFieldWrapper>

                <div className={classes['cabinet-tabs__buttons']}>
                    <CircularProgressWrapper isSubmitting={isSubmitting} fullWidth>
                        <Button
                            fullWidth
                            type="submit"
                            variant="contained"
                            className={classes['cabinet-tabs__button']}
                            onClick={handleClickShowErrors}
                            disabled={isSubmitting}
                        >
                            <FormattedMessage
                                defaultMessage="Сохранить пароль"
                                id="Cabinet_PasswordChangeSave"
                                description="кабинет"
                            />
                        </Button>
                    </CircularProgressWrapper>
                    <Button
                        type="button"
                        color="info"
                        variant="contained"
                        className={classes['cabinet-tabs__button']}
                        onClick={() => setIsEditorMode(false)}
                        disabled={isSubmitting}
                    >
                        <FormattedMessage
                            defaultMessage="Отмена"
                            id="Cabinet_PasswordChangeCancel"
                            description="кабинет"
                        />
                    </Button>
                </div>
            </form>
        </div>
    );
};
// @ts-ignore
export default observer(CabinetProfileAccountPasswordForm);
