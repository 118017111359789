import { useState } from 'react';
import { FormattedMessage, useIntl } from 'react-intl';
import { FormikHelpers, useFormik } from 'formik';
import FeedbackService from '../../../services/FeedbackService';
import * as yup from 'yup';
import { visuallyHidden } from '@mui/utils';
import { useValidationSchema } from '../../../CustomHooks/useValidationSchema';
import { useAlertContext } from '../../Context/AlertContext';
import CustomContainer from '../CustomContainer/CustomContainer';
import Typography from '@mui/material/Typography';
import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Box from '@mui/material/Box';
import Fade from '@mui/material/Fade';
import CircularProgressWrapper from '../CircularProgressWrapper/CircularProgressWrapper';
import { MESSAGE_TRANSITION_TIMEOUT } from '../../../const';
import classes from './Feedback.module.css';

interface FormValues {
    name: string;
    email: string;
    text: string;
}

function Feedback() {
    const intl = useIntl();
    const showAlert = useAlertContext();
    const { firstNameSchema, emailSchema, textSchema } = useValidationSchema();

    const [showErrors, setShowErrors] = useState(false);
    const handleClickShowErrors = () => {
        setShowErrors(true);
    };

    const formik = useFormik({
        initialValues: {
            name: '',
            email: '',
            text: ''
        },
        onSubmit: async (
            values: FormValues,
            { setSubmitting, resetForm }: FormikHelpers<FormValues>
        ) => {
            setSubmitting(true);

            try {
                const response = await FeedbackService(values);

                if (response.success) {
                    showAlert(
                        intl.formatMessage({
                            defaultMessage: 'Ваше сообщение отправлено!',
                            id: 'SendQuestion_success',
                            description: 'форма обратной связи'
                        }),
                        'success'
                    );
                    resetForm();
                } else {
                    throw new Error('Failed to send message');
                }
            } catch (error) {
                console.error('Ошибка при отправке сообщения:', error);
                showAlert(
                    intl.formatMessage({
                        defaultMessage:
                            'Во время отправки произошла ошибка. Попробуйте ещё раз позже.',
                        id: 'SendQuestion_error',
                        description: 'форма обратной связи'
                    }),
                    'error'
                );
            } finally {
                setSubmitting(false);
            }
        },
        validationSchema: yup.object().shape({
            name: firstNameSchema,
            email: emailSchema,
            text: textSchema
        })
    });

    const { isSubmitting } = formik;

    return (
        <section id="feedback" className={classes['feedback']} aria-labelledby="feedback_title">
            <CustomContainer>
                <Typography variant="h2" component="h2" sx={visuallyHidden} id="feedback_title">
                    <FormattedMessage
                        defaultMessage="Обратная связь"
                        id="Competitions_Setn/5"
                        description="форма обратной связи"
                    />
                </Typography>
                <div className={classes['feedback__form-container']}>
                    <Typography
                        className={classes['feedback__form-title']}
                        variant="h2"
                        component="h3"
                    >
                        <FormattedMessage
                            defaultMessage="Остались вопросы?"
                            id="SendQuestion_G324EQ"
                            description="форма обратной связи"
                        />
                    </Typography>
                    <Typography
                        className={classes['feedback__form-subtitle']}
                        variant="subtitle1"
                        component="p"
                    >
                        <FormattedMessage
                            defaultMessage="Заполните форму обратной связи, наш менеджер свяжется с вами в ближайшее время"
                            id="SendQuestion_28jQ8H"
                            description="форма обратной связи"
                        />
                    </Typography>

                    <form onSubmit={formik.handleSubmit} className={classes['feedback__form']}>
                        <Box sx={{ display: 'grid', minHeight: '90px' }}>
                            <TextField
                                id="name"
                                name="name"
                                label={intl.formatMessage({
                                    defaultMessage: 'Имя',
                                    id: 'SendQuestion_pAfQEy',
                                    description: 'форма обратной связи'
                                })}
                                autoComplete="name"
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                value={formik.values.name}
                                error={showErrors && !!formik.errors.name}
                                aria-describedby="nameError"
                            />
                            {formik.errors.name && showErrors && (
                                <Fade in={showErrors} timeout={MESSAGE_TRANSITION_TIMEOUT}>
                                    <p
                                        className={classes['feedback__form__error-message']}
                                        id="nameError"
                                        role="alert"
                                    >
                                        {formik.errors.name}
                                    </p>
                                </Fade>
                            )}
                        </Box>

                        <Box sx={{ display: 'grid', minHeight: '90px' }}>
                            <TextField
                                id="email"
                                name="email"
                                type="email"
                                label={intl.formatMessage({
                                    defaultMessage: 'E-mail',
                                    id: 'SendQuestion_TWT4ZP',
                                    description: 'форма обратной связи'
                                })}
                                autoComplete="email"
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                value={formik.values.email}
                                error={showErrors && !!formik.errors.email}
                                aria-describedby="emailError"
                            />

                            {formik.errors.email && showErrors && (
                                <Fade in={showErrors} timeout={MESSAGE_TRANSITION_TIMEOUT}>
                                    <p
                                        className={classes['feedback__form__error-message']}
                                        id="emailError"
                                        role="alert"
                                    >
                                        {formik.errors.email}
                                    </p>
                                </Fade>
                            )}
                        </Box>

                        <Box sx={{ display: 'grid', minHeight: '210px' }}>
                            <TextField
                                id="text"
                                name="text"
                                label={intl.formatMessage({
                                    defaultMessage: 'Вопрос',
                                    id: 'SendQuestion_ScK9pH',
                                    description: 'форма обратной связи'
                                })}
                                multiline
                                rows={5}
                                onChange={formik.handleChange}
                                onBlur={formik.handleBlur}
                                value={formik.values.text}
                                error={showErrors && !!formik.errors.text}
                                aria-describedby="textError"
                            />
                            {formik.errors.text && showErrors && (
                                <Fade in={showErrors} timeout={MESSAGE_TRANSITION_TIMEOUT}>
                                    <p
                                        className={classes['feedback__form__error-message']}
                                        id="textError"
                                        role="alert"
                                    >
                                        {formik.errors.text}
                                    </p>
                                </Fade>
                            )}
                        </Box>

                        <CircularProgressWrapper isSubmitting={isSubmitting} justify="center">
                            <Button
                                fullWidth
                                type="submit"
                                variant="contained"
                                size="large"
                                className={classes['feedback__form-button']}
                                disabled={isSubmitting}
                                onClick={handleClickShowErrors}
                            >
                                {intl.formatMessage({
                                    defaultMessage: 'Отправить',
                                    id: 'SendQuestion_juyoIy',
                                    description: 'форма обратной связи'
                                })}
                            </Button>
                        </CircularProgressWrapper>
                    </form>
                </div>
            </CustomContainer>
        </section>
    );
}

export default Feedback;
