function CabinetSubscriptionConfirmEmailMessage() {
    return (
        <p>
            Чтобы иметь возможность оформлять подписку, в том числе и пробную, подтвердите ваш
            email.
        </p>
    );
}

export default CabinetSubscriptionConfirmEmailMessage;
