import Button from '@mui/material/Button';
import classes from '../../Cabinet.module.css';

type CabinetSubscriptionActiveProps = {
    plan: string;
    subscriptionEndDate: string | undefined;
    isAutoRenew: boolean | undefined;
    isCancelable: boolean | undefined;
    amount: number | null | undefined;
    promoCodeUsagePeriods: number | null | undefined;
    handleUnsubscribe: () => void;
    handleResubscribe: () => void;
};

function CabinetSubscriptionActive({
    plan,
    subscriptionEndDate,
    isAutoRenew,
    isCancelable,
    amount,
    promoCodeUsagePeriods,
    handleUnsubscribe,
    handleResubscribe
}: CabinetSubscriptionActiveProps) {
    return (
        <div style={{ marginBlockEnd: '20px' }}>
            <p className={classes['cabinet-tabs__text']}>
                Тарифный план: <b>{plan}</b>
            </p>
            <p className={classes['cabinet-tabs__text']}>
                {isAutoRenew ? 'Следующий платёж:' : 'Подписка активна до:'}{' '}
                <b>{subscriptionEndDate}</b>
            </p>
            {isAutoRenew && !(plan !== 'GIFT') && (
                <p className={classes['cabinet-tabs__text']}>
                    Размер следующего платежа: <b>{amount} ₽</b>
                </p>
            )}
            {isAutoRenew && (promoCodeUsagePeriods ?? 0) > 0 && (
                <p className={classes['cabinet-tabs__text']}>
                    Оставшееся количество месяцев со скидкой по промо-коду:{' '}
                    <b>{promoCodeUsagePeriods}</b>
                </p>
            )}
            {isCancelable &&
                (isAutoRenew ? (
                    <Button
                        sx={{ mt: 2 }}
                        variant="contained"
                        color="warning"
                        onClick={handleUnsubscribe}
                    >
                        Отменить подписку
                    </Button>
                ) : (
                    <Button
                        sx={{ mt: 2 }}
                        variant="contained"
                        color="info"
                        onClick={handleResubscribe}
                    >
                        Возобновить подписку
                    </Button>
                ))}
        </div>
    );
}

export default CabinetSubscriptionActive;
