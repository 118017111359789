import { useEffect, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import { observer } from 'mobx-react-lite';
import { useStores } from '../../../root-store-context';
import useLocale from '../../../CustomHooks/useLocale';
import { FormattedMessage } from 'react-intl';
import { Tab, Typography } from '@mui/material';
import CustomContainer from '../CustomContainer/CustomContainer';
import TabContext from '@mui/lab/TabContext';
import TabList from '@mui/lab/TabList';
import TabPanel from '@mui/lab/TabPanel';
import CabinetProfile from './CabinetProfile/CabinetProfile';
import CabinetActivities from './CabinetActivities/CabinetActivities';
import CabinetInvites from './CabinetInvites/CabinetInvites';
import CabinetSubscription from './CabinetSubscription/CabinetSubscription';
import CabinetConfirmEmailMessage from './CabinetConfirmEmailMessage/CabinetConfirmEmailMessage';
import { NAVIGATION_ROUTES } from '../../../const';
import classes from './Cabinet.module.css';

const tabHashMap = {
    '1': 'profile',
    '2': 'subscription',
    '3': 'activities',
    '4': 'invites'
} as const;

const reverseTabHashMap = Object.entries(tabHashMap).reduce(
    (acc, [key, value]) => {
        acc[value] = key as keyof typeof tabHashMap;
        return acc;
    },
    {} as Record<string, keyof typeof tabHashMap>
);

const Cabinet = () => {
    const navigate = useNavigate();
    const { localeUrlPrefix } = useLocale();
    const { user } = useStores();
    const userData = user.data;

    const initialTabIndex = reverseTabHashMap[window.location.hash.replace('#', '')] || '1';
    const [tabIndex, setTabIndex] = useState<keyof typeof tabHashMap>(initialTabIndex);

    const handleTabIndexChange = (event: React.SyntheticEvent, newValue: string) => {
        setTabIndex(newValue as keyof typeof tabHashMap);
    };

    useEffect(() => {
        if (!user.isLogined) {
            navigate(`${localeUrlPrefix}${NAVIGATION_ROUTES.login}`);
        }
    }, []);

    useEffect(() => {
        window.location.hash = tabHashMap[tabIndex];
    }, [tabIndex]);

    useEffect(() => {
        const currentHash = window.location.hash.replace('#', '');
        const tabValue = Object.keys(tabHashMap).find(
            (key) => tabHashMap[key as keyof typeof tabHashMap] === currentHash
        );
        if (tabValue) {
            setTabIndex(tabValue as keyof typeof tabHashMap);
        }
    }, []);

    return (
        <section className={classes.cabinet} aria-labelledby="cabinet_title">
            <CustomContainer>
                <Typography
                    variant="h1"
                    component="h1"
                    color="primary"
                    className={classes['cabinet__title']}
                    id="cabinet_title"
                >
                    <FormattedMessage
                        defaultMessage="Кабинет пользователя"
                        id="Cabinet_title"
                        description="кабинет"
                    />
                </Typography>

                <div className={classes['cabinet-tabs']}>
                    {!userData?.isActivated && <CabinetConfirmEmailMessage />}
                    <TabContext value={tabIndex}>
                        <TabList
                            onChange={handleTabIndexChange}
                            aria-label="Разделы личного кабинета"
                        >
                            <Tab label="Профиль" value="1" />
                            <Tab label="Подписка" value="2" />
                            <Tab label="Активности" value="3" />
                            {userData?.isActivated && <Tab label="Приглашения" value="4" />}
                        </TabList>
                        <TabPanel value="1">
                            <CabinetProfile />
                        </TabPanel>
                        <TabPanel value="2">
                            <CabinetSubscription />
                        </TabPanel>
                        <TabPanel value="3">
                            <CabinetActivities />
                        </TabPanel>
                        {userData?.isActivated && (
                            <TabPanel value="4">
                                <CabinetInvites />
                            </TabPanel>
                        )}
                    </TabContext>
                </div>
            </CustomContainer>
        </section>
    );
};

export default observer(Cabinet);
