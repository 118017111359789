import { useEffect, useState } from 'react';
import { useStores } from '../../../../root-store-context';
import useLocale from '../../../../CustomHooks/useLocale';
import { useAlertContext } from '../../../Context/AlertContext';
import { format } from 'date-fns';
import { ru, enGB } from 'date-fns/locale';
import { FormattedMessage, useIntl } from 'react-intl';
import { Subscription } from '../../../../services/SubscriptionService';
import { CabinetSubscriptionPayments } from './CabinetSubscriptionPayments/CabinetSubscriptionPayments';

import Typography from '@mui/material/Typography';
import CabinetSubscriptionLoading from './CabinetSubscriptionLoading/CabinetSubscriptionLoading';
import CabinetSubscriptionConfirmEmailMessage from './CabinetSubscriptionConfirmEmailMessage/CabinetSubscriptionConfirmEmailMessage';
import CabinetSubscriptionPlans from './CabinetSubscriptionPlans/CabinetSubscriptionPlans';
import CabinetSubscriptionOverdue from './CabinetSubscriptionOverdue/CabinetSubscriptionOverdue';
import CabinetSubscriptionInactive from './CabinetSubscriptionInactive/CabinetSubscriptionInactive';
import CabinetSubscriptionTrial from './CabinetSubscriptionTrial/CabinetSubscriptionTrial';
import CabinetSubscriptionLFT from './CabinetSubscriptionLFT/CabinetSubscriptionLFT';
import CabinetSubscriptionActive from './CabinetSubscriptionActive/CabinetSubscriptionActive';
import CabinetSubscriptionCardInfo from './CabinetSubscriptionCardInfo/CabinetSubscriptionCardInfo';

import { SUBSCRIPTION_TYPE_MAP } from '../../../../const';
import classes from '../Cabinet.module.css';

type renderSubscriptionContentOptions = {
    subscriptionData: Subscription | undefined;
    isLoading: boolean | undefined;
    isAutoRenew: boolean | undefined;
    isOverdue: boolean | undefined;
    subscriptionEndDate: string | undefined;
    plan: string;
    handleUnsubscribe: () => void;
    handleResubscribe: () => void;
};

function renderSubscriptionContent({
    subscriptionData,
    isLoading,
    isAutoRenew,
    isOverdue,
    subscriptionEndDate,
    plan,
    handleUnsubscribe,
    handleResubscribe
}: renderSubscriptionContentOptions) {
    if (isLoading) {
        return <CabinetSubscriptionLoading />;
    }

    if (subscriptionData?.type === 'LFT') {
        return <CabinetSubscriptionLFT />;
    }

    if (subscriptionData?.isActive && subscriptionData?.type === 'TRIAL') {
        return (
            <div>
                <CabinetSubscriptionTrial subscriptionEndDate={subscriptionEndDate} />
                <div
                    className={`${classes['cabinet-tabs__forms']} ${classes['cabinet-tabs__forms--no-offset']}`}
                >
                    <CabinetSubscriptionPlans isTrial />
                </div>
                <CabinetSubscriptionPayments />
            </div>
        );
    }

    if (isOverdue) {
        return <CabinetSubscriptionOverdue onUnsubscribe={handleUnsubscribe} />;
    }

    if (!subscriptionData?.isActive) {
        return (
            <div>
                <CabinetSubscriptionInactive />
                <div
                    className={`${classes['cabinet-tabs__forms']} ${classes['cabinet-tabs__forms--no-offset']}`}
                >
                    <CabinetSubscriptionPlans isTrial={false} />
                </div>
                <CabinetSubscriptionPayments />
            </div>
        );
    }

    if (subscriptionData?.isActive) {
        return (
            <div>
                <CabinetSubscriptionActive
                    plan={plan}
                    subscriptionEndDate={subscriptionEndDate}
                    isAutoRenew={isAutoRenew}
                    isCancelable={subscriptionData.isCancelable}
                    amount={subscriptionData.amount}
                    promoCodeUsagePeriods={subscriptionData.promoCodeUsagePeriods}
                    handleUnsubscribe={handleUnsubscribe}
                    handleResubscribe={handleResubscribe}
                />
                {subscriptionData.cardInfo && isAutoRenew && (
                    <CabinetSubscriptionCardInfo cardInfo={subscriptionData.cardInfo} />
                )}
                <CabinetSubscriptionPayments />
            </div>
        );
    }

    return <div>Статус подписки неизвестен. Пожалуйста, обратитесь к разработчикам.</div>;
}

function CabinetSubscription() {
    const intl = useIntl();
    const { locale } = useLocale();
    const showAlert = useAlertContext();
    const { user, subscription } = useStores();
    const userData = user.data;
    const subscriptionData = subscription?.data;
    const [isLoading, setIsLoading] = useState(true);
    const [isAutoRenew, setIsAutoRenew] = useState(subscriptionData?.autoRenew);
    const [isOverdue, setIsOverdue] = useState(subscriptionData?.isOverdue);

    useEffect(() => {
        if (!subscriptionData) {
            subscription
                .get()
                .then(() => setIsLoading(false))
                .catch((error) => {
                    setIsLoading(false);
                    showAlert(
                        `${intl.formatMessage({
                            defaultMessage: 'Произошла ошибка:',
                            id: 'Cabinet_ErrorMessage',
                            description: 'кабинет'
                        })} ${error}`,
                        error
                    );
                });
        } else {
            setIsLoading(false);
            setIsAutoRenew(subscriptionData.autoRenew);
            setIsOverdue(subscriptionData.isOverdue);
        }
    }, [subscriptionData, subscription]);

    const handleUnsubscribe = async () => {
        try {
            await subscription.cancel();
            setIsAutoRenew(false);
            setIsOverdue(false);
        } catch (error) {
            console.error('Ошибка при отмене подписки:', error);
            showAlert('Произошла ошибка', 'error');
        }
    };

    const handleResubscribe = async () => {
        try {
            await subscription.restart();
            setIsAutoRenew(true);
        } catch (error) {
            console.error('Ошибка при рестарте подписки:', error);
            showAlert('Произошла ошибка', 'error');
        }
    };

    const mapSubscriptionType = (type: string | undefined): string => {
        return SUBSCRIPTION_TYPE_MAP[type || ''] || 'Неизвестно';
    };

    const subscriptionEndDate = subscriptionData?.activeUntil
        ? format(
            new Date(
                new Date(subscriptionData.activeUntil).setDate(
                    new Date(subscriptionData.activeUntil).getDate() - 1
                )
            ),
            'PPP',
            {
                locale: locale === 'ru' ? ru : enGB
            }
        )
        : undefined;

    return (
        <section aria-labelledby="subscription_title">
            <Typography
                variant="h3"
                component="h2"
                className={classes['cabinet-tabs__title']}
                id="subscription_title"
            >
                <FormattedMessage
                    defaultMessage="Подписка"
                    id="Cabinet_SubscriptionSectionTitle"
                    description="кабинет"
                />
            </Typography>
            {!userData?.isActivated && <CabinetSubscriptionConfirmEmailMessage />}
            {userData?.isActivated &&
                renderSubscriptionContent({
                    subscriptionData,
                    isLoading,
                    isAutoRenew,
                    isOverdue,
                    subscriptionEndDate,
                    plan: mapSubscriptionType(subscriptionData?.type),
                    handleUnsubscribe,
                    handleResubscribe
                })}
        </section>
    );
}

export default CabinetSubscription;
