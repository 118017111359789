import { makeAutoObservable } from 'mobx';
import AuthService from '../services/AuthService';
import UserService, {
    UserServiceCreateRequest,
    User,
    UserServiceUpdateRequest,
    UserServiceUpdateEmailRequest,
    UserServiceUpdatePasswordRequest,
    UserServiceSetPasswordRequest,
    UserServiceActivateRequest
} from '../services/UserService';

enum State {
    pending = 'pending',
    error = 'error',
    ok = 'ok'
}

const LAST_LOGIN_EMAIL_KEY = 'last-login-email';

class UserStore {
    constructor() {
        makeAutoObservable(this);
    }

    state: State = State.ok;

    data: User | null = null;

    error: string | undefined;

    get isLogined() {
        return this.data !== null;
    }

    get lastLoginEmail() {
        return this.data ? this.data.email : this._getLastLoginEmail();
    }

    _getLastLoginEmail() {
        return window.localStorage.getItem(LAST_LOGIN_EMAIL_KEY) || '';
    }

    _setLastLoginEmail(email: string) {
        window.localStorage.setItem(LAST_LOGIN_EMAIL_KEY, email);
    }

    async init() {
        try {
            const response = await AuthService.check();

            if (response.user) {
                this.data = response.user;
                if (typeof ym === 'function') {
                    window.ym(94984916, 'userParams', {
                        UserID: this.data.id,
                        userName: this.data.firstName,
                        userEmail: this.data.email
                    });
                } else {
                    console.error('ym is not defined');
                }
            }
        } catch (e) {}
    }

    async login(email: string, password: string) {
        this.state = State.pending;

        try {
            const response = await AuthService.login(email, password);

            if ('user' in response) {
                this.state = State.ok;
                this.data = response.user;
                this._setLastLoginEmail(response.user.email);
            } else {
                this.state = State.error;
                this.error = response.message;
            }
        } catch (e) {
            this.state = State.error;
            this.error = String(e);
        }
    }

    async logout() {
        this.state = State.pending;

        try {
            await AuthService.logout();

            this.state = State.ok;
            this.data = null;
        } catch (e) {
            this.state = State.error;
            this.error = String(e);
            this.data = null;
        }
    }

    async create(data: UserServiceCreateRequest) {
        this.state = State.pending;

        try {
            const response = await UserService.create(data);

            if ('user' in response) {
                this.state = State.ok;
                this.data = response.user;
                this._setLastLoginEmail(response.user.email);
            } else {
                this.state = State.error;
                this.error = response.message;
            }
        } catch (e) {
            this.state = State.error;
            this.error = String(e);
        }
    }

    async update(data: UserServiceUpdateRequest) {
        this.state = State.pending;

        try {
            const response = await UserService.update(data);

            if ('status' in response) {
                this.state = State.ok;
            } else {
                this.state = State.error;
                this.error = response.message;
            }
        } catch (e) {
            this.state = State.error;
            this.error = String(e);
        }
    }

    async updateEmail(data: UserServiceUpdateEmailRequest) {
        this.state = State.pending;

        try {
            const response = await UserService.updateEmail(data);

            if ('status' in response) {
                this.state = State.ok;
                if (this.data) {
                    this.data.email = data.email;
                    this._setLastLoginEmail(data.email);
                }
            } else {
                this.state = State.error;
                this.error = response.message;
            }
        } catch (e) {
            this.state = State.error;
            this.error = String(e);
        }
    }

    async updatePassword(data: UserServiceUpdatePasswordRequest) {
        this.state = State.pending;

        try {
            const response = await UserService.updatePassword(data);

            if ('status' in response) {
                this.state = State.ok;
            } else {
                this.state = State.error;
                this.error = response.message;
            }
        } catch (e) {
            this.state = State.error;
            this.error = String(e);
        }
    }

    async setPassword(data: UserServiceSetPasswordRequest) {
        this.state = State.pending;

        try {
            const response = await UserService.setPassword(data);

            if ('status' in response) {
                this.state = State.ok;
            } else {
                this.state = State.error;
                this.error = response.message;
            }
        } catch (e) {
            this.state = State.error;
            this.error = String(e);
        }
    }

    async activate(data: UserServiceActivateRequest) {
        this.state = State.pending;

        // TODO много однотипного кода, подумать над унификацией
        try {
            const response = await UserService.activate(data);

            if ('status' in response) {
                this.state = State.ok;
                if (this.data) {
                    this.data.isActivated = true;
                }
            } else {
                this.state = State.error;
                this.error = response.message;
            }
        } catch (e) {
            this.state = State.error;
            this.error = String(e);
        }
    }
}

export default UserStore;
