import { buildApiUrl } from './api';

export type User = {
    id: number;
    firstName: string;
    lastName: string | null;
    email: string;
    role: string;
    isActivated: boolean;
    needsPasswordReset: boolean;
};

type ErrorResponse = {
    code: string;
};

type FaultResponse = {
    message: string;
    errors: ErrorResponse[];
};

type SuccessfulCreateBody = {
    user: User;
};

type UserServiceCreateResponse =
    | (Omit<Response, 'json'> & {
          status: 201;
          json: () => SuccessfulCreateBody | PromiseLike<SuccessfulCreateBody>;
      })
    | (Omit<Response, 'json'> & {
          status: 400;
          json: () => BadRequestBody | PromiseLike<BadRequestBody>;
      });

type SuccessfulUpdateBody = {
    status: Boolean;
};

type UserServiceUpdateResponse =
    | (Omit<Response, 'json'> & {
          status: 200;
          json: () => SuccessfulUpdateBody | PromiseLike<SuccessfulUpdateBody>;
      })
    | (Omit<Response, 'json'> & {
          status: 400;
          json: () => BadRequestBody | PromiseLike<BadRequestBody>;
      });

type UserServiceUpdateEmailResponse =
    | (Omit<Response, 'json'> & {
          status: 200;
          json: () => SuccessfulUpdateBody | PromiseLike<SuccessfulUpdateBody>;
      })
    | (Omit<Response, 'json'> & {
          status: 400;
          json: () => BadRequestBody | PromiseLike<BadRequestBody>;
      })
    | (Omit<Response, 'json'> & {
          status: 404;
          json: () => BadRequestBody | PromiseLike<BadRequestBody>;
      });

type UserServiceUpdatePasswordResponse =
    | (Omit<Response, 'json'> & {
          status: 200;
          json: () => SuccessfulUpdateBody | PromiseLike<SuccessfulUpdateBody>;
      })
    | (Omit<Response, 'json'> & {
          status: 400;
          json: () => BadRequestBody | PromiseLike<BadRequestBody>;
      });

type UserServiceSendActivationLinkResponse = Omit<Response, 'json'> & {
    status: 200;
    json: () => SuccessfulUpdateBody | PromiseLike<SuccessfulUpdateBody>;
};

type UserServiceActivateResponse =
    | (Omit<Response, 'json'> & {
          status: 200;
          json: () => SuccessfulUpdateBody | PromiseLike<SuccessfulUpdateBody>;
      })
    | (Omit<Response, 'json'> & {
          status: 400;
          json: () => BadRequestBody | PromiseLike<BadRequestBody>;
      });

type UserServiceSendRecoveryPasswordLinkResponse = Omit<Response, 'json'> & {
    status: 200;
    json: () => SuccessfulUpdateBody | PromiseLike<SuccessfulUpdateBody>;
};

type UserServiceRecoveryPasswordResponse =
    | (Omit<Response, 'json'> & {
          status: 200;
          json: () => SuccessfulUpdateBody | PromiseLike<SuccessfulUpdateBody>;
      })
    | (Omit<Response, 'json'> & {
          status: 400;
          json: () => BadRequestBody | PromiseLike<BadRequestBody>;
      });

type BadRequestBody = FaultResponse;

export type UserServiceCreateRequest = {
    firstName: string;
    lastName: string | null;
    password: string;
    email: string;
};

export type UserServiceUpdateRequest = {
    firstName: string;
    lastName: string | null;
};

export type UserServiceUpdateEmailRequest = {
    email: string;
    currentPassword: string;
};

export type UserServiceUpdatePasswordRequest = {
    password: string;
    currentPassword: string;
};

export type UserServiceSetPasswordRequest = {
    password: string;
};

export type UserServiceActivateRequest = {
    token: string;
};

export type UserServiceSendRecoveryPasswordLinkRequest = {
    email: string;
};

export type UserServiceRecoveryPasswordRequest = {
    token: string;
    password: string;
};

class UserService {
    static async create(data: UserServiceCreateRequest) {
        const response = (await fetch(buildApiUrl('/user/create'), {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data)
        })) as UserServiceCreateResponse;

        if (response.status === 201 || response.status === 400) {
            return response.json();
        }

        throw Error('Unhandled response code');
    }

    static async update(data: UserServiceUpdateRequest) {
        const response = (await fetch(buildApiUrl('/user/update'), {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data)
        })) as UserServiceUpdateResponse;

        if (response.status === 200 || response.status === 400) {
            return response.json();
        }

        throw Error('Unhandled response code');
    }

    static async updateEmail(data: UserServiceUpdateEmailRequest) {
        const response = (await fetch(buildApiUrl('/user/update/email'), {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data)
        })) as UserServiceUpdateEmailResponse;

        if (response.status === 200 || response.status === 400) {
            return response.json();
        }

        if (response.status === 404) {
            throw Error('Current password is not valid');
        }

        throw Error('Unhandled response code');
    }

    static async updatePassword(data: UserServiceUpdatePasswordRequest) {
        const response = (await fetch(buildApiUrl('/user/update/password'), {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data)
        })) as UserServiceUpdatePasswordResponse;

        if (response.status === 200 || response.status === 400) {
            return response.json();
        }

        throw Error('Unhandled response code');
    }

    static async setPassword(data: UserServiceSetPasswordRequest) {
        const response = (await fetch(buildApiUrl('/user/set/password'), {
            method: 'PUT',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data)
        })) as UserServiceUpdatePasswordResponse;

        if (response.status === 200 || response.status === 400) {
            return response.json();
        }

        throw Error('Unhandled response code');
    }

    static async sendActivationLink() {
        const response = (await fetch(buildApiUrl('/user/send-activation-link'), {
            method: 'POST'
        })) as UserServiceSendActivationLinkResponse;

        if (response.status === 200) {
            return response.json();
        }

        throw Error('Unhandled response code');
    }

    static async activate(data: UserServiceActivateRequest) {
        const response = (await fetch(buildApiUrl('/user/activate'), {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data)
        })) as UserServiceActivateResponse;

        if (response.status === 200 || response.status === 400) {
            return response.json();
        }

        throw Error('Unhandled response code');
    }

    static async sendRecoveryPasswordLink(data: UserServiceSendRecoveryPasswordLinkRequest) {
        const response = (await fetch(buildApiUrl('/user/send-recovery-password-link'), {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data)
        })) as UserServiceSendRecoveryPasswordLinkResponse;

        if (response.status === 200 || response.status === 400) {
            return response.json();
        }

        throw Error('Unhandled response code');
    }

    static async recoveryPassword(data: UserServiceRecoveryPasswordRequest) {
        const response = (await fetch(buildApiUrl('/user/recovery-password'), {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json'
            },
            body: JSON.stringify(data)
        })) as UserServiceRecoveryPasswordResponse;

        if (response.status === 200 || response.status === 400) {
            return response.json();
        }

        throw Error('Unhandled response code');
    }
}

export default UserService;
