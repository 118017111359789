import { FormattedMessage, useIntl } from 'react-intl';
import * as yup from 'yup';
import { useFormik } from 'formik';
import { useAlertContext } from '../../../../Context/AlertContext';

import TextField from '@mui/material/TextField';
import Button from '@mui/material/Button';
import Typography from '@mui/material/Typography';
import MenuItem from '@mui/material/MenuItem';
import CircularProgressWrapper from '../../../CircularProgressWrapper/CircularProgressWrapper';

import { buyPromoCode } from '../../../../../services/promoCodeService';

import classes from '../../Cabinet.module.css';

const PRICE_PER_MONTH = 1000;

function CabinetInvitesBuyGiftForm() {
    const intl = useIntl();
    const showAlert = useAlertContext();

    const formik = useFormik({
        initialValues: {
            months: 3
        },
        validationSchema: yup.object({
            months: yup
                .number()
                .min(1, intl.formatMessage({ defaultMessage: 'Минимум 1 месяц' }))
                .max(6, intl.formatMessage({ defaultMessage: 'Максимум 6 месяцев' }))
                .required(intl.formatMessage({ defaultMessage: 'Это поле обязательно' }))
        }),
        onSubmit: async (values) => {
            console.log("Months: ", values.months);
            const response = await buyPromoCode({ months: values.months });

            const paymentUrl = response.result?.paymentUrl;

            if (paymentUrl) {
                window.location.href = paymentUrl;
            } else {
                showAlert(
                    intl.formatMessage({
                        defaultMessage: 'Произошла ошибка',
                        id: 'Cabinet_PersonalChangeError',
                        description: 'кабинет'
                    }),
                    'error'
                );
            }
        }
    });

    const { isSubmitting, values, errors, handleChange, handleSubmit } = formik;

    return (
        <form
            onSubmit={handleSubmit}
            className={classes['cabinet-tabs__form']}
            aria-label={intl.formatMessage({
                defaultMessage: 'Форма покупки подписки для друга',
                id: 'GiftSubscriptionFormTitle',
                description: 'кабинет'
            })}
        >
            <Typography
                sx={{ mt: 2 }}
                variant="h4"
                component="h3"
                className={classes['cabinet-tabs__subtitle']}
            >
                <FormattedMessage
                    defaultMessage="Подарить подписку WattAttack"
                    id="GiftSubscriptionFormHeader"
                    description="кабинет"
                />
            </Typography>

            <TextField
                select
                label={intl.formatMessage({
                    defaultMessage: 'Количество месяцев',
                    id: 'GiftSubscriptionMonthsLabel',
                    description: 'кабинет'
                })}
                name="months"
                value={values.months}
                onChange={handleChange}
                error={!!errors.months}
                helperText={errors.months}
                fullWidth
                margin="normal"
            >
                {[...Array(6)].map((_, index) => (
                    <MenuItem key={index + 1} value={index + 1}>
                        {index + 1}
                    </MenuItem>
                ))}
            </TextField>

            <Typography variant="h6" className={classes['cabinet-tabs__total']}>
                <FormattedMessage
                    defaultMessage="Итоговая сумма: {price} руб."
                    id="GiftSubscriptionTotalPrice"
                    description="кабинет"
                    values={{ price: values.months * PRICE_PER_MONTH }}
                />
            </Typography>

            <CircularProgressWrapper isSubmitting={isSubmitting} fullWidth justify="start">
                <Button
                    type="submit"
                    variant="contained"
                    size="large"
                    className={`${classes['cabinet-tabs__button']} ${classes['cabinet-tabs__button--buy']}`}
                    disabled={isSubmitting}
                >
                    <FormattedMessage
                        defaultMessage="Подарить"
                        id="GiftSubscriptionSubmitButton"
                        description="кабинет"
                    />
                </Button>
            </CircularProgressWrapper>
        </form>
    );
}

export default CabinetInvitesBuyGiftForm;
