import useLocale from '../../../CustomHooks/useLocale';
import { observer } from 'mobx-react-lite';
import { useStores } from '../../../root-store-context';
import { MouseEventHandler } from 'react';
import { FormattedMessage } from 'react-intl';
import { NavLink, useLocation, useNavigate } from 'react-router-dom';
import Button from '@mui/material/Button';
import LogoutIcon from '@mui/icons-material/Logout';
import { NAVIGATION_ROUTES, SCROLL_OFFSET } from '../../../const';
import classes from './MobileMainMenu.module.css';

type MobileMainMenuProps = {
    clickHandler?: MouseEventHandler<HTMLAnchorElement> | undefined;
};

function MobileMainMenu({ clickHandler }: MobileMainMenuProps): React.JSX.Element {
    const { localeUrlPrefix } = useLocale();
    const { user } = useStores();
    const navigate = useNavigate();
    const location = useLocation();

    const scrollTo = () => {
        const sectionElement = document.getElementById(
            location.hash.slice(1, location.hash.length)
        );
        const offset = SCROLL_OFFSET;
        if (sectionElement) {
            const targetScroll = sectionElement.offsetTop - offset;
            sectionElement.scrollIntoView({ behavior: 'smooth' });
            window.scrollTo({
                top: targetScroll
            });
        }
    };

    //Грешновато
    const combinedHandler = (event: React.MouseEvent<HTMLAnchorElement, MouseEvent>) => {
        scrollTo();
        if (clickHandler) {
            clickHandler(event); // Вызов основного обработчика
        }
    };

    const logout = async () => {
        await user.logout();

        navigate(`${localeUrlPrefix}${NAVIGATION_ROUTES.login}`);
    };

    return (
        <ul className={classes['mobile-main-menu']}>
            <li className={classes['mobile-main-menu__item']}>
                <NavLink
                    className={classes['mobile-main-menu__link']}
                    to={`${localeUrlPrefix}${NAVIGATION_ROUTES.how_to_start}`}
                    onClick={combinedHandler}
                >
                    <FormattedMessage
                        id="Header_B1QVBw"
                        defaultMessage="Как начать"
                        description="элемент меню"
                    />
                </NavLink>
            </li>
            <li className={classes['mobile-main-menu__item']}>
                <NavLink
                    className={classes['mobile-main-menu__link']}
                    to={`${localeUrlPrefix}${NAVIGATION_ROUTES.maps}`}
                    onClick={combinedHandler}
                >
                    <FormattedMessage
                        id="Header_hm2zoA"
                        defaultMessage="Карты"
                        description="элемент меню"
                    />
                </NavLink>
            </li>
            <li className={classes['mobile-main-menu__item']}>
                <NavLink
                    className={classes['mobile-main-menu__link']}
                    to={`${localeUrlPrefix}${NAVIGATION_ROUTES.workouts}`}
                    onClick={combinedHandler}
                >
                    <FormattedMessage
                        id="Header_HkU1aZ"
                        defaultMessage="Тренировки"
                        description="элемент меню"
                    />
                </NavLink>
            </li>
            <li className={classes['mobile-main-menu__item']}>
                <NavLink
                    className={classes['mobile-main-menu__link']}
                    to={`${localeUrlPrefix}${NAVIGATION_ROUTES.about}`}
                    onClick={combinedHandler}
                >
                    <FormattedMessage
                        id="Header_Rl27q3"
                        defaultMessage="О нас"
                        description="элемент меню"
                    />
                </NavLink>
            </li>
            {user.isLogined && (
                <li className={classes['mobile-main-menu__item']}>
                    <NavLink
                        className={classes['mobile-main-menu__link']}
                        to={`${localeUrlPrefix}${NAVIGATION_ROUTES.cabinet_subscription}`}
                        onClick={combinedHandler}
                    >
                        <FormattedMessage
                            id="Header_J9oe0h"
                            defaultMessage="Подписка"
                            description="элемент меню"
                        />
                    </NavLink>
                </li>
            )}
            {!user.isLogined && (
                <li className={classes['mobile-main-menu__item']}>
                    <NavLink
                        className={classes['mobile-main-menu__link']}
                        to={`${localeUrlPrefix}${NAVIGATION_ROUTES.subscription}`}
                        onClick={combinedHandler}
                    >
                        <FormattedMessage
                            id="Header_J9oe0h"
                            defaultMessage="Подписка"
                            description="элемент меню"
                        />
                    </NavLink>
                </li>
            )}
            <li className={classes['mobile-main-menu__item']}>
                <NavLink
                    className={classes['mobile-main-menu__link']}
                    to={`${localeUrlPrefix}${NAVIGATION_ROUTES.help}`}
                    onClick={combinedHandler}
                >
                    <FormattedMessage
                        id="Header_8h6EWK"
                        defaultMessage="Помощь"
                        description="элемент меню"
                    />
                </NavLink>
            </li>
            <li className={classes['mobile-main-menu__item']}>
                <NavLink
                    className={classes['mobile-main-menu__link']}
                    to={`${localeUrlPrefix}${NAVIGATION_ROUTES.download}`}
                    onClick={combinedHandler}
                >
                    <FormattedMessage
                        id="Header_LT99Hk"
                        defaultMessage="Скачать"
                        description="элемент меню"
                    />
                </NavLink>
            </li>

            {user.isLogined &&
                location.pathname !== `${localeUrlPrefix}${NAVIGATION_ROUTES.cabinet}` && (
                    <li className={classes['mobile-main-menu__item']}>
                        <NavLink
                            className={classes['mobile-main-menu__link']}
                            to={`${localeUrlPrefix}${NAVIGATION_ROUTES.cabinet}`}
                        >
                            <FormattedMessage
                                id="Header_FgmjAT"
                                defaultMessage="Кабинет"
                                description="элемент меню"
                            />
                        </NavLink>
                    </li>
                )}

            {!user.isLogined && (
                <li className={classes['mobile-main-menu__item']}>
                    <NavLink
                        className={classes['mobile-main-menu__link']}
                        to={`${localeUrlPrefix}${NAVIGATION_ROUTES.login}`}
                    >
                        <FormattedMessage
                            id="Header_tNozdx"
                            defaultMessage="Войти"
                            description="элемент меню"
                        />
                    </NavLink>
                </li>
            )}

            {user.isLogined && (
                <li className={classes['mobile-main-menu__item']}>
                    <Button
                        className={`${classes['mobile-main-menu__link']} ${classes['mobile-main-menu__link--logout']}`}
                        onClick={logout}
                        startIcon={<LogoutIcon />}
                    >
                        <FormattedMessage
                            defaultMessage="Выйти"
                            id="Cabinet_logoutAttr"
                            description="форма входа"
                        />
                    </Button>
                </li>
            )}
        </ul>
    );
}

export default observer(MobileMainMenu);
