import { FormattedMessage } from 'react-intl';
import Typography from '@mui/material/Typography';
import CabinetTabsProfilePersonForm from './CabinetProfilePersonForm/CabinetProfilePersonForm';
import CabinetTabsProfilePersonAthleteForm from './CabinetProfilePersonAthleteForm/CabinetProfilePersonAthleteForm';
import classes from '../../Cabinet.module.css';

function CabinetProfilePerson() {
    return (
        <section className={classes['cabinet-tabs__section']} aria-labelledby="personal-data_title">
            <Typography
                variant="h3"
                component="h2"
                className={classes['cabinet-tabs__title']}
                id="personal-data_title"
            >
                <FormattedMessage
                    defaultMessage="Личные данные"
                    id="Cabinet_PersonalSectionTitle"
                    description="Кабинет"
                />
            </Typography>
            <p className={classes['cabinet-tabs__text']}>
                <FormattedMessage
                    defaultMessage="Настройки пользователя в игре. Вы можете их поменять также на странице пользователя в приложении WattAttack."
                    id="Cabinet_PersonalSectionDesc"
                    description="Кабинет"
                />
            </p>
            <div className={classes['cabinet-tabs__forms']}>
                <CabinetTabsProfilePersonForm />
                <CabinetTabsProfilePersonAthleteForm />
            </div>
        </section>
    );
}

export default CabinetProfilePerson;
