import { ReactNode, useEffect } from 'react';
import { ScrollProvider } from '../../Context/ScrollContext';
import { ViewportProvider } from '../../Context/ViewPortContext';
// jest-ignore
import { PaletteColorOptions, ThemeProvider, createTheme } from '@mui/material/styles';
import CssBaseline from '@mui/material/CssBaseline';

import { StyledEngineProvider } from '@mui/material/styles';

import '@fontsource-variable/roboto-condensed';
import '@fontsource-variable/oswald';
import { AlertProvider } from '../../Context/AlertContext';
import CookieConsent from '../../Components/CookieConsent/CookieConsent';
import { SCROLL_OFFSET } from '../../../const';
import { useLocation } from 'react-router-dom';

declare module '@mui/material/styles' {
    interface PaletteOptions {
        light?: PaletteColorOptions;
    }

    interface PaletteColor {
        light: string;
    }

    interface Palette {
        light: {
            main: string;
            light: string;
            dark: string;
            contrastText: string;
        };
    }
}

const WATheme = createTheme({
    palette: {
        primary: {
            light: '#FD7A33',
            main: '#FD5900',
            dark: '#B13E00',
            contrastText: '#fff'
        },
        secondary: {
            light: '#404B53',
            main: '#111F28',
            dark: '#0B151C',
            contrastText: '#000'
        },
        light: {
            light: '#E0E0E0',
            main: '#FFF',
            dark: '#424242',
            contrastText: '#000'
        }
    },
    typography: {
        fontFamily: 'Roboto Condensed Variable, sans-serif',
        body1: {
            fontSize: '1.25rem'
        },
        h1: {
            fontFamily: 'Oswald Variable, sans-serif',
            fontSize: '4rem'
        },
        h2: {
            marginBlockEnd: '15px',
            fontFamily: 'Oswald Variable, sans-serif',
            fontSize: '3rem',
            fontWeight: 700,
            textAlign: 'center'
        },
        h3: {
            fontFamily: 'Oswald Variable, sans-serif',
            fontSize: '2rem',
            fontWeight: 700,
            marginBlockEnd: 20
        },
        h4: {
            fontFamily: 'Oswald Variable, sans-serif',
            fontSize: '1.4rem',
            fontWeight: 600,
            marginBlockEnd: 7
        },
        subtitle1: {
            fontSize: '1.5rem',
            textAlign: 'center'
        },
        subtitle2: {
            fontSize: '1.25rem'
        }
    },
    components: {
        MuiButton: {
            styleOverrides: {
                root: {
                    fontFamily: 'Oswald Variable, sans-serif',
                    fontWeight: '500'
                },
                sizeLarge: {
                    fontSize: '1.4rem'
                },
                contained: {
                    '&.MuiButton-sizeMedium': {
                        paddingBlockStart: '9px'
                    }
                }
            }
        },
        MuiInputLabel: {
            styleOverrides: {
                root: {
                    transform: 'translate(14px, 17px) scale(1)',
                    '&.Mui-focused': {
                        transform: 'translate(14px, -10px) scale(0.75)'
                    }
                },
                shrink: {
                    transform: 'translate(14px, -10px) scale(0.75)'
                }
            }
        }
    }
});

type Props = {
    children: ReactNode;
    className?: String;
};

function AppLayout({ children }: Props) {
    const location = useLocation();

    useEffect(() => {
        if (location.hash) {
            const element = document.querySelector(location.hash);
            if (element instanceof HTMLElement) {
                const offset = SCROLL_OFFSET;
                const targetScroll = element.offsetTop - offset;
                window.scrollTo({
                    top: targetScroll,
                    behavior: 'smooth'
                });
            }
        } else {
            window.scrollTo(0, 0);
        }
    }, [location.hash]);

    return (
        <ThemeProvider theme={WATheme}>
            <CssBaseline />
            <ViewportProvider>
                <ScrollProvider>
                    <AlertProvider>
                        <StyledEngineProvider injectFirst>{children}</StyledEngineProvider>
                        <CookieConsent />
                    </AlertProvider>
                </ScrollProvider>
            </ViewportProvider>
        </ThemeProvider>
    );
}

export default AppLayout;
