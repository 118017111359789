import ReactDOM from 'react-dom/client';
import { createBrowserRouter, RouteObject, RouterProvider } from 'react-router-dom';
import { IntlProvider, MessageFormatElement, useIntl } from 'react-intl';
import Cookies from 'js-cookie';

import './index.css';

import MainPage from './UI/pages/MainPage';
import LoginPage from './UI/pages/LoginPage';
import LogoutPage from './UI/pages/LogoutPage/LogoutPage';
import RegistrationPage from './UI/pages/RegistrationPage';
import RecoveryPasswordPage from './UI/pages/RecoveryPasswordPage';
import CabinetPage from './UI/pages/CabinetPage';
import ActivateUserPage from './UI/pages/ActivateUserPage';
import AppPayPage from './UI/pages/AppPayPage/AppPayPage';
import CabinetActivitiesPage from './UI/pages/CabinetActivitiesPage';
import PrivacyPolicyPage from './UI/pages/PrivacyPolicy/PrivacyPolicy';
import ErrorPage from './UI/pages/ErrorPage/ErrorPage';
import SupportPage from './UI/pages/SupportPage/SupportPage';

import useLocale, {
    LOCALE_COOKIE_NAME,
    buildPathInLocale,
    extractLocaleFromPath,
    getDefaultLocale,
    isDefaultLocale,
    isSupportedLocale
} from './CustomHooks/useLocale';
import { ReactNode, useState } from 'react';
import { RootStoreProvider } from './root-store-context';
import RootStore from './stores/RootStore';
import { NAVIGATION_ROUTES } from './const';
import SubscriptionSupportPage from './UI/pages/SubscriptionSupportPage/SubscriptionSupportPage';

async function loadLocaleMessages(locale: string) {
    let messages;

    switch (locale) {
        case 'en':
            messages = await import('./intl/compiled/en.json');
            break;
        default:
            messages = await import('./intl/compiled/ru.json');
    }

    return messages.default;
}

const rawRoutes = [
    {
        path: NAVIGATION_ROUTES.root,
        element: MainPage
    },
    {
        path: NAVIGATION_ROUTES.login,
        element: LoginPage
    },
    {
        path: NAVIGATION_ROUTES.logout,
        element: LogoutPage
    },
    {
        path: NAVIGATION_ROUTES.registration,
        element: RegistrationPage
    },
    {
        path: '/register',
        element: () => {
            window.location.replace(NAVIGATION_ROUTES.registration);
            return null;
        }
    },
    {
        path: NAVIGATION_ROUTES.password_recovery,
        element: RecoveryPasswordPage
    },
    {
        path: NAVIGATION_ROUTES.cabinet,
        element: CabinetPage
    },
    {
        path: NAVIGATION_ROUTES.user_activate,
        element: ActivateUserPage
    },
    {
        path: NAVIGATION_ROUTES.activities,
        element: CabinetActivitiesPage
    },
    {
        path: NAVIGATION_ROUTES.privacy_policy,
        element: PrivacyPolicyPage
    },
    {
        path: NAVIGATION_ROUTES.app_pay,
        element: AppPayPage
    },
    {
        path: NAVIGATION_ROUTES.subscription_support,
        element: SubscriptionSupportPage
    },
    {
        path: NAVIGATION_ROUTES.support,
        element: SupportPage
    },
    {
        path: '/*',
        element: ErrorPage
    }
];

type LocalCheckProps = {
    onLocaleChange: (locale: string) => void;
    children: ReactNode;
    locale: string;
};

function LocaleCheck({ locale, children, onLocaleChange }: LocalCheckProps) {
    const intl = useIntl();

    if (intl.locale !== locale) {
        onLocaleChange(locale);
    }

    return <>{children}</>;
}

type RootProps = {
    initialLocale: string;
    initialLocaleMessages: Record<string, MessageFormatElement[]>;
};

const rootStore = new RootStore();

function Root({ initialLocale, initialLocaleMessages }: RootProps) {
    const { allLocales, defaultLocale } = useLocale();

    const [locale, setLocale] = useState(initialLocale);
    const [localeMessages, setLocaleMessages] = useState(initialLocaleMessages);

    const handleLocaleChange = async (locale: string) => {
        Cookies.set(LOCALE_COOKIE_NAME, locale);
        setLocale(locale);
        setLocaleMessages(await loadLocaleMessages(locale));
    };

    const routes: RouteObject[] = [];
    const addLocaleRoutes = (locale: string, localeUrlPrefix: string) => {
        rawRoutes.forEach((route) => {
            routes.push({
                path: `${localeUrlPrefix}${route.path}`,
                element: (
                    <LocaleCheck locale={locale} onLocaleChange={handleLocaleChange}>
                        <route.element />
                    </LocaleCheck>
                )
            });
        });
    };

    addLocaleRoutes(defaultLocale, '');
    allLocales.forEach((locale) => {
        addLocaleRoutes(locale, `/${locale}`);
    });

    return (
        <RootStoreProvider value={rootStore}>
            <IntlProvider locale={locale} messages={localeMessages}>
                <RouterProvider router={createBrowserRouter(routes)} />
            </IntlProvider>
        </RootStoreProvider>
    );
}

async function bootstrapApplication() {
    const path = document.location.pathname;
    const localeFromUrl = extractLocaleFromPath(path);
    let locale = localeFromUrl || getDefaultLocale();

    if (localeFromUrl) {
        Cookies.set(LOCALE_COOKIE_NAME, localeFromUrl);

        // Убираем префикс-локаль из урла для дефолтной локали /ru/login => /login
        if (isDefaultLocale(localeFromUrl)) {
            document.location.pathname = buildPathInLocale(localeFromUrl, path);

            return;
        }
    } else {
        const localeFromCookie = Cookies.get(LOCALE_COOKIE_NAME);

        // Если есть кука с локалью, отличной от дефолтной /login => /en/login
        if (
            localeFromCookie &&
            isSupportedLocale(localeFromCookie) &&
            !isDefaultLocale(localeFromCookie)
        ) {
            document.location.pathname = buildPathInLocale(localeFromCookie, path);

            return;
        }

        // Если нет куки с локалью, пользователь зашёл первый раз, то пытаемся получить её из настроек браузера
        if (!localeFromCookie) {
            const localeFromNavigator = window.navigator?.languages.find((locale) =>
                isSupportedLocale(locale)
            );

            if (localeFromNavigator && !isDefaultLocale(localeFromNavigator)) {
                document.location.pathname = buildPathInLocale(localeFromNavigator, path);

                return;
            }
        }

        Cookies.set(LOCALE_COOKIE_NAME, locale);
    }

    const [localeMessages] = await Promise.all([loadLocaleMessages(locale), rootStore.init()]);

    ReactDOM.createRoot(document.getElementById('root') as HTMLElement).render(
        <Root initialLocale={locale} initialLocaleMessages={localeMessages} />
    );
}

bootstrapApplication();
