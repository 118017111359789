import AppLayout from '../../layouts/AppLayout/AppLayout';
import MainHeader from '../../Components/MainHeader/MainHeader';
import MainFooter from '../../Components/MainFooter/MainFooter';
import CabinetActivities from '../../Components/Cabinet/CabinetActivities/CabinetActivities';

function CabinetActivitiesPage() {
    return (
        <AppLayout>
            <MainHeader variant="cabinet" />
            <main>
                <CabinetActivities />
            </main>
            <MainFooter />
        </AppLayout>
    );
}

export default CabinetActivitiesPage;
