import { useState } from 'react';
import { useStores } from '../../../../../root-store-context';
import { FormattedMessage } from 'react-intl';
import Typography from '@mui/material/Typography';
import Button from '@mui/material/Button';
import CabinetProfileAccountEmailForm from './CabinetProfileAccountEmailForm/CabinetProfileAccountEmailForm';
import CabinetProfileAccountSetPasswordForm from './CabinetProfileAccountSetPasswordForm/CabinetProfileAccountSetPasswordForm';
import CabinetProfileAccountPasswordForm from './CabinetProfileAccountPasswordForm/CabinetProfileAccountPasswordForm';
import classes from '../../Cabinet.module.css';

function CabinetProfileAccount() {
    const { user } = useStores();
    const userData = user.data;
    const [isEmailEditorMode, setIsEmailEditorMode] = useState<boolean>(false);
    const [isPasswordEditorMode, setIsPasswordEditorMode] = useState<boolean>(false);
    const [isSetPasswordShowed, setIsSetPasswordShowed] = useState<boolean>(
        userData?.needsPasswordReset === true
    );

    const handleEmailEditClick = () => {
        setIsEmailEditorMode((prev) => !prev);
    };

    const handlePasswordEditClick = () => {
        setIsPasswordEditorMode((prev) => !prev);
    };

    return (
        <section className={classes['cabinet-tabs__section']} aria-labelledby="account_title">
            <Typography
                variant="h3"
                component="h2"
                className={classes['cabinet-tabs__title']}
                id="account_title"
            >
                <FormattedMessage
                    defaultMessage="Учётная запись"
                    id="Cabinet_AccountSectionTitle"
                    description="Кабинет"
                />
            </Typography>
            <p className={classes['cabinet-tabs__text']}>
                <FormattedMessage
                    defaultMessage="Здесь вы можете поменять адрес электронной почты (который также является логином) и пароль."
                    id="Cabinet_AccountSectionDesc"
                    description="Кабинет"
                />
            </p>
            <div className={classes['cabinet-tabs__forms']}>
                {isEmailEditorMode && (
                    <CabinetProfileAccountEmailForm setIsEditorMode={setIsEmailEditorMode} />
                )}
                {isPasswordEditorMode && (
                    <CabinetProfileAccountPasswordForm setIsEditorMode={setIsPasswordEditorMode} />
                )}
                {isSetPasswordShowed && (
                    <CabinetProfileAccountSetPasswordForm
                        setIsEditorMode={setIsSetPasswordShowed}
                    />
                )}
                <div
                    className={`${classes['cabinet-tabs__buttons']} ${classes['cabinet-tabs__buttons--offset']}`}
                >
                    {!isEmailEditorMode && (
                        <Button
                            type="button"
                            onClick={handleEmailEditClick}
                            className={classes['cabinet-tabs__button']}
                            variant="contained"
                        >
                            <FormattedMessage
                                defaultMessage="Изменить email"
                                id="Cabinet_EmailChange"
                                description="кабинет"
                            />
                        </Button>
                    )}

                    {!isPasswordEditorMode && !isSetPasswordShowed && (
                        <Button
                            type="button"
                            onClick={handlePasswordEditClick}
                            className={classes['cabinet-tabs__button']}
                            variant="contained"
                        >
                            <FormattedMessage
                                defaultMessage="Изменить пароль"
                                id="Cabinet_PasswordChangeChange"
                                description="кабинет"
                            />
                        </Button>
                    )}
                </div>
            </div>
        </section>
    );
}

export default CabinetProfileAccount;
