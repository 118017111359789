import { FormattedMessage } from 'react-intl';
import Typography from '@mui/material/Typography';
import CircularProgress from '@mui/material/CircularProgress';

function CabinetSubscriptionLoading() {
    return (
        <div>
            <Typography>
                <FormattedMessage
                    defaultMessage="Загрузка данных о подписке"
                    id="Cabinet_SubscriptionDataLoad"
                    description="кабинет"
                />
            </Typography>
            <CircularProgress />
        </div>
    );
}

export default CabinetSubscriptionLoading;
